import { useEffect, useState } from "react";
import { IconNotification } from "@/assets/Icon/IconNotification";
import imgProfile from "@/assets/imgProfile.png";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currenciesState } from "@/apps/Vank/Page/state";
import { formatBalance } from "@/apps/Vank/Page/Home/Home";
import { IndexedDBLocalDropdownRepository } from "@/apps/Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import {
  ProfileUser,
  counterSelector,
  globalAmount,
} from "@/apps/Vank/Page/Home/state";
import { ServicesHome } from "@/apps/Vank/Page/Home/Services/ServicesHome";
import ErrorsModal from "@/apps/Shared/Errors/ErrorsModal/ErrorsModal";
import { useTranslation } from "react-i18next";
import HighlightWords from "@/apps/Shared/HighlightWords/HighlightWords";
import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import SidebarNotifications from "./SidebarNotifications/SidebarNotifications";
import ModalNotificationsMobile from "@/apps/Vank/Page/Home/Modales/ModalNotificationsMobile/ModalNotificationsMobile";
import ModalProfileMoibile from "@/apps/Vank/Page/Home/Modales/ModalProfileMobile/ModalProfileMoibile";

const checkPathname = (pathname, paths) => {
  return paths.some((path) =>
    pathname.toLowerCase().includes(path.toLowerCase())
  );
};

const UserProfileAndNotifications = () => {
  //Estado para la función de traducción
  const [t] = useTranslation("global");
  const servicesHome = new ServicesHome();

  const [currencyState, setCurrencyState] = useRecoilState(currenciesState);
  const [amount, setAmount] = useRecoilState(globalAmount);
  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const location = useLocation();
  const pathsToCheck = ["/home", "/Home"];
  const [statusError, setStateCodeError] = useState<number>();
  const [visibleError, setVisibleError] = useState<boolean>(false);
  const [counter, setCounter] = useRecoilState(counterSelector);

  const isPathMatched = checkPathname(location.pathname, pathsToCheck);

  //modal notifications
  const [isOpenModalNotifications, setIsOpenModalNotifications] = useState(false);
  //  Modal profile
  const [isOpenModalProfile, setIsOpenModalProfile] = useState(false);


  const toggleShowBalance = () => {
    // setShowBalance(prevState => !prevState);
    setCurrencyState((currentState) => ({
      ...currentState,
      showBalance: !currentState.showBalance,
    }));
  };

  useEffect(() => {
    fetchData();
    console.log("entroo a userpro");

  }, [amount?.currency]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const fetchData = async () => {
    try {
      document.dispatchEvent(new CustomEvent("ReloadAmount", { detail: true }));
      const response = await servicesHome.getOperationsHome(amount?.currency);
      const parsedCurrencies = JSON.parse(response.currencies);
      const parsedWallets = JSON.parse(response.wallets);
      const balance = {
        amountGlobal: response.amountGlobal,
        amountGlobalUsd: response.amountGlobalUsd,
        currencies: parsedCurrencies,
        wallets: parsedWallets,
      };
      setVisibleError(false);

      // console.log(balance);

      setAmount((amount: any) => ({
        ...amount,
        amount: balance,
      }));
      document.dispatchEvent(
        new CustomEvent("ReloadAmount", { detail: false })
      );
    } catch (error) {
      // setVisibleError(true);
      // setStateCodeError(error.status);
    }
  };

  const getUserProfile = async () => {
    new IndexedDBLocalDropdownRepository()
      .get("user")
      .then((userStored) => {
        const users = userStored[0];
        if (users) {
          setUsers((user: any) => ({
            ...user,
            ...users,
          }));
          const _counter = users?.STATUSINCODE === 2 ? 1 : 0;
          setCounter(_counter);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const hanldeModalNotification = () => {
    setIsOpenModalNotifications(!isOpenModalNotifications)
  }

  const handleModalProfile = () => {
    setIsOpenModalProfile(!isOpenModalProfile)
  }

  return (
    <div className="w-full  h-12 justify-end items-center gap-6 inline-flex ">
      {!isPathMatched && (
        <div className="w-full h-[46px] flex-col justify-center items-end  inline-flex">
          <div className="justify-center items-center gap-2 inline-flex ">
            <button
              className="w-[18px] h-[18px] relative  "
              onClick={toggleShowBalance}
            >
              {currencyState.showBalance ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M14.5747 6.65835L15.7414 7.82502C16.0518 8.13729 16.2261 8.55971 16.2261 9.00002C16.2261 9.44033 16.0518 9.86275 15.7414 10.175L13.7164 12.2C12.9192 13.0019 11.9311 13.5879 10.845 13.9028C9.75893 14.2177 8.61062 14.2511 7.50807 14M5.08307 12.8834C4.78731 12.6784 4.50862 12.4499 4.24974 12.2L2.25807 10.175C1.94765 9.86275 1.77342 9.44033 1.77342 9.00002C1.77342 8.55971 1.94765 8.13729 2.25807 7.82502L4.28307 5.80002C5.38812 4.69201 6.84771 4.00805 8.40629 3.86788C9.96486 3.72772 11.5231 4.14029 12.8081 5.03335M7.42474 10.3584C7.087 9.95648 6.91343 9.44192 6.93873 8.91758C6.96403 8.39324 7.18634 7.89779 7.5612 7.5303C7.93606 7.16281 8.43584 6.95038 8.96057 6.93549C9.48531 6.92061 9.99632 7.10437 10.3914 7.45002M16.4081 1.55835L1.44141 16.525"
                    stroke="#818282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M14.714 7.1286L16.7411 9.15563C17.0536 9.46819 17.2292 9.89212 17.2292 10.3341C17.2292 10.7762 17.0536 11.2001 16.7411 11.5127L14.714 13.5397C13.4638 14.7899 11.7681 15.4923 10 15.4923C8.23189 15.4923 6.5362 14.7899 5.28596 13.5397L3.25892 11.5127C2.94636 11.2001 2.77076 10.7762 2.77076 10.3341C2.77076 9.89212 2.94636 9.46819 3.25892 9.15563L5.28595 7.1286C6.5362 5.87835 8.23189 5.17597 10 5.17597C11.7681 5.17597 13.4638 5.87835 14.714 7.1286Z"
                    stroke="#818282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.0003 12.4167C11.1509 12.4167 12.0837 11.4839 12.0837 10.3333C12.0837 9.18274 11.1509 8.25 10.0003 8.25C8.84973 8.25 7.91699 9.18274 7.91699 10.3333C7.91699 11.4839 8.84973 12.4167 10.0003 12.4167Z"
                    stroke="#818282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </button>
            <span className="text-right text-[#575859] text-xs font-normal leading-[18px]">
              {t(
                "apps.Shared.layout.Header.UserProfileAndNotifications.UserProfileAndNotifications.Saldoglobaldisponível"
              )}
            </span>
          </div>
          <div className="w-full flex justify-end items-center gap-1 ">
            <span className="max-w-max self-stretch text-right text-[--color-darkest-grey] text-xl font-bold leading-7">
              {currencyState.showBalance
                ? formatBalance(amount?.amount?.amountGlobal)
                : "*******"}
            </span>
            <span className="text-right text-[--color-darkest-grey] text-xl font-bold leading-7">
              {" "}
              {currencyState?.selectCurrency?.name}
            </span>
          </div>
        </div>
      )}
      {/* <UserProfileAndNotifications user={user} /> */}
      <div className="2xl:min-w-[112px] xl:min-w-[112px] lg:min-w-[112px] md:min-w-[112px] sm:min-w-[112px] mobile:min-w-[85px]  h-[48px] flex justify-between items-center ">
        <button
          className={`relative w-12  h-12 rounded-full border-[1px] border-[#C9CBCE] justify-center items-center flex 2xl:flex xl:flex lg:flex md:flex sm:flex mobile:hidden  ${counter === 7 && "z-50 bg-white"
            }`}
          onClick={() =>
            document.dispatchEvent(new Event("SliderNotifications"))
          }
          style={{
            pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
          }}
        >
          <div className="w-2 h-2 right-[15px] top-3 absolute rounded-full border border-white" />
          <IconNotification />
          {counter === 7 && (
            <div className=" absolute min-w-[482px] min-h-[54px] rounded-[16px] top-1/2 -translate-y-1/2 right-[140%] flex justify-start items-center bg-white py-4 pl-4 pr-8 z-50">
              <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[#FFF133]">
                <span className="text-[14px] text-[#000] font-normal">
                  {counter}
                </span>
              </div>
              <p className="text-left text-[18px] text-[#2D2E2F] font-normal leading-[21.78px]">
                {t("Vank.Home.Onboarding.Seven")}
              </p>
            </div>
          )}


        </button>

        {/* mobile */}
        <button
          className={`relative  w-9  h-9 rounded-full border-[1px] border-[#C9CBCE] justify-center items-center flex 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden mobile:flex  ${counter === 7 && "z-50 bg-white"
            }`}
          onClick={hanldeModalNotification}
          style={{
            pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
          }}
        >
          <div className="w-2 h-2 right-[8px] top-2 absolute  rounded-full border border-white" />
          <IconNotification />
          {/* mensaje mobile */}
          {counter === 7 && (
            <div className="2xl:hidden  xl:hidden lg:flex md:flex sm:flex mobile:flex absolute mobile:min-w-[180px] mobile-375:min-w-[230px] min-h-[54px] rounded-[16px] top-1/2 -translate-y-1/2 right-[140%] flex justify-start items-center bg-white py-4 px-4 z-50">
              <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[#FFF133]">
                <span className="text-[14px] text-[#000] font-normal">
                  {counter}
                </span>
              </div>
              <p className="text-center text-[18px] text-[#2D2E2F] font-normal leading-[21.78px]">
                {t("Vank.Home.Onboarding.Seven")}
              </p>
            </div>
          )}
        </button>
        {/*  */}

        {users?.STATUSINCODE === 2 ? (
          <div
            className={`relative w-12 h-12 rounded-full ${counter === 8 && "z-50"
              }`}
            style={{
              pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
            }}
          >
            <img
              className="w-12 h-12 rounded-full cursor-pointer"
              src={imgProfile}
            />
            {counter === 8 && (
              <div className="2xl:flex  xl:flex lg:flex md:flex sm:hidden mobile:hidden  absolute w-[306px] min-h-[98px] rounded-[16px] top-1/2 -translate-y-1/2 right-[140%] flex justify-center items-center bg-white py-4 pl-4 pr-8 z-50">
                <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[#FFF133]">
                  <span className="text-[14px] text-[#000] font-normal">
                    {counter}
                  </span>
                </div>
                <p className="w-[434px] text-[18px] text-[#000] font-normal leading-[21.78px]">
                  {HighlightWords(t("Vank.Home.Onboarding.Eight.text"), [
                    t("Vank.Home.Onboarding.Eight.boldText").toLowerCase(),
                  ])}
                </p>
              </div>
            )}

            {/* mobile mensaje 8 */}
            {counter === 8 && (
              <div className="2xl:hidden  xl:hidden lg:hidden  md:hidden sm:flex mobile:flex absolute  min-h-[98px]  mobile:w-[200px] mobile-375:w-[270px] rounded-[16px] mobile-375:top-1/2 mobile:top-[45px] -translate-y-1/2 right-[140%] flex justify-center items-center bg-white py-4 px-4 z-50">
                <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[#FFF133]">
                  <span className="text-[14px] text-[#000] font-normal">
                    {counter}
                  </span>
                </div>
                <p className="w-[434px] text-[18px] text-[#000] font-normal leading-[21.78px]">
                  {HighlightWords(t("Vank.Home.Onboarding.Eight.text"), [
                    t("Vank.Home.Onboarding.Eight.boldText").toLowerCase(),
                  ])}
                </p>
              </div>
            )}
          </div>
        ) : users?.AVATAR ? (
          <div>
            <img
              className="w-12 h-12 rounded-full cursor-pointer 2xl:flex xl:flex lg:flex md:flex sm:flex mobile:hidden"
              src={users?.AVATAR}
              onClick={() => document.dispatchEvent(new Event("SliderProfile"))}
            />

            {/* mobile */}
            <img
              className="w-9 h-9 rounded-full cursor-pointer 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden mobile:flex"
              src={users?.AVATAR}
              onClick={handleModalProfile}
            />
            {/*  */}
          </div>
        ) : (
          <div className="w-12 h-12 rounded-full cursor-pointer animate-pulse bg-slate-200" />
        )}
      </div>

      {/* <ErrorsModal
        visible={visibleError}
        onClick={() => {
          setVisibleError(false);
        }}
        statusError={statusError}
      /> */}

      <CustomModal isOpen={isOpenModalNotifications}>
        <ModalNotificationsMobile onClose={hanldeModalNotification} />
      </CustomModal>

      <CustomModal isOpen={isOpenModalProfile}>
        <ModalProfileMoibile onClose={handleModalProfile} />
      </CustomModal>
    </div>
  );
};

export default UserProfileAndNotifications;
