import React, { useEffect, useState } from 'react'
import imgProfile from '@assets/imgProfile.png'
import { motion } from 'framer-motion';
import { backStyle } from '@/apps/Shared/CustomModal/CustomModal';
import { useTranslation } from 'react-i18next';

const Income = ({ item }) => {
    //Estado para la función de traducción
    const [t] = useTranslation("global");
    return (
        <motion.div className='relative w-full h-[64px]'
            initial={backStyle.initial}
            animate={backStyle.animate}
            exit={backStyle.exit}
        >
            <div className='w-full  h-[64px] px-3 py-2 justify-between items-center gap-4 inline-flex '>
                <div className=" relative w-[51px] h-12 justify-start items-end inline-flex ">
                    <img className=" relative rounded-full" src={imgProfile} />
                    <div className="w-4 h-4 absolute right-0 bg-[#B7EB8F] rounded-full flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M3.88 8.12086L8.12 3.88086M3.88 8.12086H6.425M3.88 8.12086V5.57586" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>

                <div className="w-full  flex-col items-start gap-2 inline-flex">
                    <div className=" text-[--color-darkest-grey] text-xs font-normal  leading-none">{item?.name}</div>
                    <div className="text-[--color-darkest-grey] text-[10px] font-normal leading-none">{item?.date}</div>
                </div>

                {item?.status === 1 && (
                    <div className="w-2 h-2 bg-[--color-bright-yellow] rounded-full" />
                )}
            </div>
            <div className="absolute bottom-0 left-0 w-full h-px flex-col justify-center items-end gap-2.5 inline-flex">
                <div className="w-full h-px bg-gray-200" />
            </div>
        </motion.div>
    )
};

const Expense = ({ item }) => {

    return (
        <motion.div className='relative w-full h-[64px] '
            initial={backStyle.initial}
            animate={backStyle.animate}
            exit={backStyle.exit}>
            <div className='w-full h-[64px] px-3 py-2 justify-between items-center gap-4 inline-flex'>

                <div className="relative w-[51px] h-12 justify-start items-end inline-flex ">
                    <img className=" relative rounded-full" src={imgProfile} />
                    <div className="w-4 h-4 absolute right-0 bg-[#FCC3C3] rounded-full flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M8.12 3.88086L3.88 8.12086M8.12 3.88086H5.575M8.12 3.88086V6.42586" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>

                <div className="w-full flex-col items-start gap-2 inline-flex">
                    <div className=" text-[--color-darkest-grey] text-xs font-normal  leading-none">{item?.name}</div>
                    <div className=" text-[--color-darkest-grey] text-[10px] font-normal leading-none">{item?.date}</div>
                </div>

                {item?.status === 1 && (
                    <div className="w-2 h-2 bg-[--color-bright-yellow] rounded-full" />
                )}
            </div>
            <div className="absolute bottom-0 left-0 w-full h-px flex-col justify-center items-end gap-2.5 inline-flex">
                <div className="w-full h-px bg-gray-200" />
            </div>
        </motion.div>
    )
};

const Conversion = ({ item }) => {

    return (
        <motion.div className='relative w-full h-[64px] '
            initial={backStyle.initial}
            animate={backStyle.animate}
            exit={backStyle.exit}>
            <div className='w-full h-[64px] px-3 py-2 justify-between items-center gap-4 inline-flex'>

                <div className="relative w-[51px] h-12 justify-start items-end inline-flex ">
                    <img className=" relative rounded-full" src={imgProfile} />
                    <div className="w-4 h-4 absolute right-0 bg-[--color-bright-yellow] rounded-full flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M3.5 7.45714L5 9V3M8.5 4.54286L7 3V9" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>

                <div className="w-full  flex-col items-start gap-2 inline-flex">
                    <div className="text-[--color-darkest-grey] text-xs font-normal  leading-none">{item?.name}</div>
                    <div className="text-[--color-darkest-grey] text-[10px] font-normal leading-none">{item?.date}</div>
                </div>

                {item?.status === 1 && (
                    <div className="w-2 h-2 bg-[--color-bright-yellow] rounded-full" />
                )}
            </div>
            <div className="absolute bottom-0 left-0 w-full h-px flex-col justify-center items-end gap-2.5 inline-flex">
                <div className="w-full h-px bg-gray-200" />
            </div>
        </motion.div>
    )
};

const _listNotifications = [
    {
        name:  "Recibiste 2.300 USDT de Alexandra Gómez",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "income"
    },
    {
        name: "Enviaste 18.000 USDT de Alexandra Hernández",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "expense"
    },
    {
        name: "Enviaste 18.000 USDT de Alexandra Hernández",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "expense"
    },
    {
        name: "Recibiste 2.300 USDT de Alexandra Gómez",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "income"
    },
    {
        name: "Enviaste 18.000 USDT de Alexandra Hernández",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "expense"
    },
    {
        name: "Enviaste 18.000 USDT de Alexandra Hernández",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "conversion"
    },
    {
        name: "Recibiste 2.300 USDT de Alexandra Gómez",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "income"
    },
    {
        name: "Enviaste 18.000 USDT de Alexandra Hernández",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "expense"
    },
    {
        name: "Recibiste 2.300 USDT de Alexandra Gómez",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "income"
    },
    {
        name: "Enviaste 18.000 USDT de Alexandra Hernández",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "expense"
    },
    {
        name: "Recibiste 2.300 USDT de Alexandra Gómez",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "income"
    },
    {
        name: "Enviaste 18.000 USDT de Alexandra Hernández",
        date: "Viernes 10:04 a.m.",
        status: 1,
        type: "expense"
    },
];



const ModalNotificationsMobile = ({onClose} : {onClose:any;}) => {
    //Estado para la función de traducción
    const [t] = useTranslation("global");
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [notificationCounts, setNotificationCounts] = useState({
        all: 0,
        expense: 0,
        income: 0,
        conversion: 0,
    });
    const [isOpenSend, setIsOpenSend] = useState(false);
    const [listNotifications, setListNotifications] = useState([]);

    useEffect(() => {
        if (_listNotifications) {
            setListNotifications(_listNotifications)
        }
        document.addEventListener('SliderNotifications2', () => setIsOpenSend(true))
    }, []);

    useEffect(() => {
        if (listNotifications) {
            const counts = listNotifications.reduce((acc: any, notification) => {
                if (notification.status === 1) {
                    acc[notification.type] = (acc[notification.type] || 0) + 1;
                    acc.all = (acc.all || 0) + 1;
                }
                return acc;
            }, {});
            setNotificationCounts(counts);
        }
    }, [listNotifications]);

    useEffect(() => {
        document.addEventListener('SliderNotifications2', () => setIsOpenSend(true))
    }, []);

    const nav = [
        { name: t("Vank.Share.Notifications.All"), value: 'all', count: notificationCounts?.all },
        { name: t("Vank.Share.Notifications.Transactions"), value: 'expense', count: notificationCounts?.expense },
        { name: t("Vank.Share.Notifications.Deposits"), value: 'income', count: notificationCounts?.income },
        { name: t("Vank.Share.Notifications.Conversions"), value: 'conversion', count: notificationCounts?.conversion },
    ];


    const filteredNotifications = listNotifications.filter(notification => {
        if (selectedFilter === 'all') return true;
        return notification.type === selectedFilter;
    })

    const handleResetStatus = () => {
        const updatedNotifications = listNotifications.map(notification =>
            notification.status === 1 ? { ...notification, status: 0 } : notification
        );
        setListNotifications(updatedNotifications);
    };

    return (
        <div className={`w-full rounded-t-[24px]  absolute  bottom-0  mobile-375:h-[30rem]  mobile:h-[20rem]  flex flex-col justify-start items-center bg-[#FFF] shadow `}>
        <div className='w-full  p-4 flex justify-between items-center border-b border-b-[#F1F1F1]'>
            <span className="w-[109px] text-[--color-darkest-grey] text-base font-normal">{t("Vank.Share.Notifications.title")}</span>

            <div className="w-[209.50px] h-7 justify-end items-center gap-2 inline-flex">
                <button className="text-[#818282] text-[10px] font-medium underline" onClick={handleResetStatus}>{t("Vank.Share.Notifications.label")}</button>
                <div className="p-1 border border-[#818282] rounded-full flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
                        onClick={() => setIsOpenSend(onClose)}
                    >
                        <path d="M5 5L13 13" stroke="#818282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13 5L5 13" stroke="#818282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
        </div>

        <div className='w-full flex items-center p-[16px] overflow-x-scroll'>
            <div className='w-full h-8 p-1  rounded-lg justify-start items-center inline-flex '>
                {nav.map((item, index) => (
                    <>
                        <div 
                        key={index}
                        className={` ${index === 0 ? ' py-[3px]' : ''} h-6 px-2 ${selectedFilter === item?.value ? 'bg-[--color-darkest-grey] shadow' : 'bg-transparent'} rounded justify-center items-center gap-1 inline-flex`}
                            onClick={() => setSelectedFilter(item?.value)}
                        >
                            <div className={`text-center ${selectedFilter === item?.value ? 'text-white' : 'text-[--color-darkest-grey]'} text-xs font-medium leading-[18px]`}>{item?.name}</div>
                            {item?.count > 0 && (
                                <div className="min-w-4 min-h-4 bg-yellow-300 rounded-lg flex-col justify-center items-center inline-flex">
                                    <div className="text-center text-zinc-800 text-[10px] font-semibold leading-[18px]">{item?.count}</div>
                                </div>
                            )}
                        </div>
                        {index < nav?.length - 1 && <div className='min-w-[1px] min-h-[8px] bg-[#F1F1F1]' />}
                    </>
                ))}
            </div>
        </div>

        <div className='relative w-full flex flex-col gap-3 overflow-auto  '>
            {filteredNotifications.map((transaction, index) => {
                switch (transaction.type) {
                    case 'income':
                        return <Income key={index} item={transaction} />;
                    case 'expense':
                        return <Expense key={index} item={transaction} />;
                    case 'conversion':
                        return <Conversion key={index} item={transaction} />;
                    default:
                        return null;
                }
            })}
        </div>
    </div>
    )
}

export default ModalNotificationsMobile