import SharedButtons from "@/apps/Vank/Page/shared/SharedButtons/SharedButtons";
import { formtDate } from "@/apps/Vank/Page/utils/Utilfunctions";
import { IconClose } from "@/assets/IconV2/IconsSvg";
import COP from "@assets/img/COP.png"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import succes from '@assets/img/Success.png';
import error from '@assets/img/error.png';
import pending from '@assets/img/pending.png';

function ModalApprovedShipping({ data, onClose }: { data: any, onClose: (visible: boolean) => void }) {
    const [t] = useTranslation("global");
    const [fill, setFill] = useState<string>("var(--content-main-gray-light)");



    return (
        <div className="w-full flex items-center justify-center">
            <div className="flex py-[24px]  2xl:w-[540px] xl:w-[540px] lg:w-[540px] md:w-[540px] sm:w-[540px] mobile:w-full bottom-0 
    2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute  flex-col justify-center items-center 2xl:2xl:gap-[24px] xl:gap-[5px] md:gap-[0px]   rounded-[16px] bg-[var(--bacground-component-module-light)]">
                <div className="flex  flex-col items-start gap-[16px] w-full">
                    <div className="flex justify-between px-[24px] w-full items-center">
                        <p className="text-[var(--content-main-black-light)] text-[20px] font-semibold">Resumen de transacción</p>
                        <div
                            onMouseEnter={() => setFill('var(--content-inner-inner-white-light)')}
                            onMouseLeave={() => setFill('var(--content-main-gray-light)')}
                            onClick={() => onClose(false)}
                            className={`h-8 w-8 cursor-pointer rounded-full hover:bg-[var(--background-tab-selected)]`}
                        >
                            <IconClose fill={fill} />
                        </div>
                    </div>
                    <div className="h-[1px] w-full bg-[var(--strokes-separator-separator-light)]"></div>
                </div>
                <div className="flex w-[50px] h-[50px] justify-center items-center gap-[10px] rounded-[44px]">
                    {data.STATUSORDER == 1 ? <img src={succes} alt="" /> : data.STATUSORDER == 2 ? <img src={pending} alt="" /> : <img src={error} alt="" />}
                </div>
                <div className="flex px-[24px] flex-col items-center 2xl:gap-[24px] xl:gap-[5px] md:gap-[0px]  w-full">
                    {data.STATUSORDER == 1 ?
                        <div className="flex flex-col items-center gap-[8px] w-full">
                            <p className="text-[20px] text-[var(--content-main-black-light)] text-center font-semibold">Transacción aprobada</p>
                            <p className="text-[var(--content-main-gray-light)] text-center text-[14px] font-normal">{formtDate(data.GENERATEDDATE)}</p>
                            <p className="text-[var(--content-main-gray-light)] text-center text-[14px] font-normal">{t("Transactions.Fiat.components.ModalApprovedShipping.text4")} <span className="font-medium">{data.CODERECEIPT}</span></p>
                        </div>
                        : data.STATUSORDER == 2 ?
                            <div className="flex flex-col items-center gap-[8px] w-full">
                                <p className="text-[20px] text-[var(--content-main-black-light)] text-center font-semibold">Tu dinero va en Camino</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-medium text-center">Tu transferencia está en proceso de validación Por favor, espera unos minutos mientras el banco verifica tu envío.</p>
                                <p className="text-[var(--content-main-gray-light)] text-center text-[14px] font-normal">{formtDate(data.GENERATEDDATE)}</p>
                                <p className="text-[var(--content-main-gray-light)] text-center text-[14px] font-normal">{t("Transactions.Fiat.components.ModalApprovedShipping.text4")} <span className="font-medium">{data.CODERECEIPT}</span></p>
                            </div>
                            : <div className="flex flex-col items-center gap-[8px] w-full">
                                <p className="text-[20px] text-[var(--content-main-black-light)] text-center font-semibold">Envío de dinero fallido</p>
                                <p className="text-[var(--content-main-gray-light)] text-center text-[14px] font-normal">{formtDate(data.GENERATEDDATE)}</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-medium text-center">Hubo un problema en el servidor. Por favor vuelve a intentarlo en unos minutos.</p>
                            </div>}

                    <div className="flex flex-col items-start gap-[16px] w-full">
                        <div className="flex justify-center items-center gap-[8px] w-full">
                            <div className="w-[28px] h-[28px] me-2">
                                <img src={COP} alt="" />
                            </div>
                            <div className=" flex items-center justify-center">
                                <p className='flex justify-center items-center font-semibold text-[18px] text-[var(--content-main-black-light)]'>{data.AMOUNT} <span className="font-normal mx-2"> {data.ASSETNAME}</span></p>
                            </div>
                        </div>
                        <div className="flex p-[16px] flex-col items-start gap-[8px] rounded-[12px] bg-[var(--background-layaout-light)] w-full">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal">Destinatario</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal">{data.NAME}</p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal">Identificación</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal">{atob(data.IDENTITYNUMBER)}</p>
                            </div>
                            <div className="h-[1px] w-full bg-[#F1F1F1]"></div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal">Banco de destino</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal">{data.BANKNAME}</p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal">Tipo de cuenta</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal">{data.BANKACCOUNTTYPENAME}</p>
                            </div>
                            <div className="h-[1px] w-full bg-[#F1F1F1]"></div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal">No. de cuenta</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-semibold">{data.ACCOUNT_NUMBER}</p>
                            </div>
                            {/* <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal">Costo transacción</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal"><span className="font-semibold">{data.FEE * 100}% </span>(${data.DISACOUNT} {data.ASSETNAME})</p>
                            </div> */}
                            <div className="h-[1px] w-full bg-[#F1F1F1]"></div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal">Descripción:</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal">{data.DESCRIPTION}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center 2xl:gap-[24px] xl:gap-[5px] md:gap-[0px]  w-full">
                        <SharedButtons typeModule={3} URL={data.IMGURL} TypeAction='' CODERECEIPT={data.CODERECEIPT} email={data.EMAIL} />
                        <div className="flex flex-col gap-[10px] w-full">
                            <button className="flex py-[10px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] bg-[var(--branding-brand-yellow-light)] transition duration-300 hover:bg-[var(--background-tab-selected)] group" onClick={() => onClose(false)}>
                                <p className="flex justify-center items-center gap-[8px] text-[var(--content-inner-inner-black-light)] text-[16px] font-semibold transition duration-300 group-hover:text-[var(--branding-brand-yellow-light)]">
                                    Terminar transacción
                                </p>
                            </button>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    );
}

export default ModalApprovedShipping;
