import { Storage } from "./Storage";

export class IndexedDBLocalDropdownRepository extends Storage {
  public async searchAll(key): Promise<any[]> {
    const resul: any = await this.get(key);

    return resul ? resul : [];
  }

  public async save(params: { key; data: any[] }): Promise<void> {
    return this.set(params.key, params.data);
  }

  public async removeAll(key: string): Promise<void> {
    return this.remove(key);
  }
}
