import CustomButton from '@/apps/Shared/CustomButton/CustomButton'
import React from 'react'
import { useTranslation } from 'react-i18next';

export const CustomButtonsForms = ({ onClickClear, onClickContinue, disabled, type, label, labelClear }: { labelClear?: string, onClickClear?: () => void, onClickContinue?: () => void, disabled?: boolean, type?: string, label?: string }) => {

    return (
        <div className='w-full flex gap-6 mb-8'>
            <div className='w-1/2'>
                <CustomButton onclick={onClickClear} type={type} label= "limpiar" className='font-bold border-[1px] border-[var(--content-main-black-light)] text-[var(--content-main-black-light)] rounded-full h-[42px] w-full' />
            </div>
            <div className='w-1/2'>
                <CustomButton onclick={onClickContinue} label={label} type={type} 
                className={`${disabled?'bg-[var(--background-disabled-button)] text-[var(--content-main-gray-light)]':'bg-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] hover:text-[var(--branding-brand-yellow-light)]'}  rounded-full h-[42px] w-full`} disabled={disabled} />
            </div>
        </div>
    )
}
