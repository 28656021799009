import UserProfileAndNotifications from '@/apps/Shared/layout/Header/UserProfileAndNotifications/UserProfileAndNotifications'
import { ArrowHide } from '@/assets/Icon/ArrowHide'
import { IconArrowSend, IconsLots } from '@/assets/IconV2/IconsSvg';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { TableOfRecipients } from './Tables/TableOfRecipients/TableOfRecipients';
import { TableTransactionFiat } from './Tables/TableTransactionFiat/TableTransactionFiat';
import CustomModal from '@/apps/Shared/CustomModal/CustomModal';
import ModalchangingSections from './components/ModalChangingSections/ModalChangingSections';
import Lotes from '@assets/Icons/Vector.png';

const Fiat = () => {

    const [isActive, setIsActive] = useState(true);
    const [viewModelQR, setViewModelQR] = useState<boolean>(false)
    const [colorStroke, setColorStroke] = useState<string>("var(--content-inner-inner-black-light)")
    const [openModalChangingSections, setOpenModalChangingSections] = useState<boolean>(false); //estado para la modal Changing Section

    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    const nav = [
        { id: 1, name: t("Vank.Transaction.VankPay.SendVankPay.HeaderOfShares.Actions.VankPay"), link: "/send/vankpay" },
        // { id: 2, name: t("Vank.Transaction.VankPay.SendVankPay.HeaderOfShares.Actions.Crypto"), link: "/send/crypto", active: false },
        { id: 2, name: t("Vank.Transaction.VankPay.SendVankPay.HeaderOfShares.Actions.Fiat"), link: "/send/fiat", active: true },
    ];

    const handleStroke = () => {
        setColorStroke("var(--branding-brand-yellow-light)")
    }

    //funcion para cambiar el estado true o false de la modal
    const openChangingSections = () => {
        setOpenModalChangingSections(!openModalChangingSections);
    }

    const navLots = () => {

        setIsActive(false)
        navigate("lots")
    }

    return (
        <div className="w-full flex flex-col py-[32px] px-[64px] gap-4 ">

            <div className="w-full  flex-col mt-4 justify-center items-start gap-4 inline-flex ">

                <div className="w-full flex-col    items-start justify-center gap-2 inline-flex">

                    <div className="relative w-full  -top-4">
                        <div className="absolute right-0 top-0">
                            <UserProfileAndNotifications />
                        </div>
                    </div>

                    <button className="min-w-max  min-h-4 justify-start items-center gap-1 flex">
                        <div className="w-2.5 h-4">
                            <ArrowHide className="rotate-90 w-full h-full" stroke="var(--content-main-black-light)" />{t(" ")}
                        </div>
                        <span className="text-[var(--content-main-black-light)] text-base font-normal">{t("Vank.Transaction.Money.SendMoney.back")}</span>
                    </button>

                    <div className="w-full h-12 flex-col justify-start items-start inline-flex">
                        <div className="w-[156px] h-7 justify-start items-center gap-1 inline-flex">
                            <span className="text-[var(--content-main-black-light)] text-[22px] font-semibold">{t("Vank.Transaction.Money.SendMoney.Send")}</span>
                        </div>
                        <span className="text-[var(--content-main-black-light)] text-sm font-normal leading-tight">{t("Vank.Transaction.Money.SendMoney.SendMessage")}</span>
                    </div>
                </div>

                <div className="w-full  2xl:flex xl:flex 2xl:flex-row xl:flex-row md:block sm:block gap-5  justify-between items-center ">

                    <div className="w-full  h-9 gap-5 rounded-lg justify-between items-center inline-flex pr-4">

                        <div className='h-9 bg-[var(--Background-tab-background)] rounded-lg justify-start items-center inline-flex'>
                            {nav.map(({ active, name, link, id }) => (
                                <button
                                    key={id}
                                    className={`w-[100px] h-[36px] ${active === isActive
                                        ? "flex items-center justify-center gap-4 px-6 rounded-[8px] bg-[var(--background-tab-selected)]"
                                        : "p-1 flex flex-col items-center gap-4 rounded-[2px] "
                                        } cursor-pointer`}
                                    onClick={() => {
                                        setIsActive(true)
                                        navigate(link)
                                    }}
                                >
                                    {active === isActive ? (
                                        <span className="text-[var(--content-inner-inner-white-light)] text-base leading-[24px]">
                                            {name}
                                        </span>
                                    ) : (
                                        <div className="w-full h-full flex items-center justify-center px-5 bg-[var(--Background-tab-background)] rounded-[5.344px]">
                                            {name}
                                        </div>
                                    )}
                                </button>
                            ))}
                        </div>

                        <div className='hidden'>
                            <button onClick={() => navLots()} className={`${!isActive && 'bg-[var(--content-main-black-light)] text-[#FFF]'}  flex items-center gap-2 py-[6px] pr-6 pl-4 rounded-full text-sm border border-[var(--content-main-black-light)]`}>
                                <div className='flex w-[12px] h-[12px] justify-center items-center'>
                                    {/* <img src={Lotes} alt={t("Transactions.Fiat.Fiat.Lotslogo")} className='w-full h-full'/> */}
                                    <IconsLots stroke={!isActive?'white':'var(--content-main-black-light)'}/>
                                </div>
                                <p>{t("Vank.Transaction.Money.SendMoney.HeaderOfShares.Lotes")}</p>
                            </button>
                        </div>

                    </div>

                    <div className='w-full  flex 2xl:justify-end xl:justify-end md:justify-start sm:justify-start 2xl:mt-0 xl:mt-0 md:mt-4 sm:mt-4'>
                        <button onClick={openChangingSections} onMouseLeave={() => setColorStroke("var(--content-inner-inner-black-light)")} onMouseEnter={() => handleStroke()} className="group min-w-[106px] h-7 pl-4 pr-6 py-1.5 bg-[var(--branding-brand-yellow-light)] rounded-[999px] justify-center items-center gap-2 flex hover:bg-[var(--background-tab-selected)] ">
                            <div className="  w-5 h-5 justify-center items-center">
                                <IconArrowSend className='w-5 h-5 rotate-180 font-[700]' stroke={colorStroke} />
                            </div>
                            <p className="group-hover:text-[var(--branding-brand-yellow-light)]  text-var(--content-inner-inner-black-light) text-sm font-medium">{t("Vank.Transaction.Money.SendMoney.HeaderOfShares.Recharge.Text")}</p>
                        </button>
                    </div>

                </div>
            </div>

            {/* <div className="flex max-lg:flex-col gap-4  w-full  center">

                <div className="flex flex-col  w-full">

                    <SendFiat />

                </div>

                <div className="flex flex-col gap-4  w-full overflow-auto">

                    <TableOfRecipients />
                    <TableTransactionFiat />

                </div>

            </div> */}
            <div>
                <Outlet />
            </div>
            <CustomModal isOpen={openModalChangingSections}>
                <ModalchangingSections onClose={setOpenModalChangingSections} type="send" link="/receive/fiat" />
            </CustomModal>
        </div>
    )
}

export default Fiat;