import SliderCard from "./SliderCard/SliderCard";
import NextView from "./NextView/NextView";
import { useEffect, useState } from "react";
import { counterSelector, ProfileUser } from "../state";
import { useRecoilState } from "recoil";

const ManageCards = () => {
  const [show, setShow] = useState(true);
  const [counter, setCounter] = useRecoilState(counterSelector);
  const [users, setUsers] = useRecoilState<any>(ProfileUser);


  useEffect(() => {
    if (users?.STATUSINCODE === 2) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [counter]);

  return (
    <>
      {show ? <NextView setShow={setShow} /> : <SliderCard setShow={setShow} />}
    </>
  );
};

export default ManageCards;
