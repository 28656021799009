// import { getItem, setItem, removeItem } from '../localStorage/store';
// import { environment } from '@environment/environment';
import { get, set, del } from "idb-keyval";

export interface LocalTable<T> {
  value: T;
  timestamp: number;
  version: string;
}

interface _LocalTable {
  value: any;
  timestamp: number;
  version: string;
}

export class Storage {
  constructor() {}

  // Función para codificar en base64
  private toBase64(str: string): string {
    return btoa(str);
  }

  // Función para decodificar de base64
  private fromBase64(str: string): string {
    return atob(str);
  }

  //   public get(key: string): Promise<_LocalTable | undefined> {
  //     return get(key);
  //   }

  // Recuperar datos de IndexedDB y decodificar de base64
  public async get(key: string): Promise<any | undefined> {
    // Codificar la clave en base64
    const encodedKey = this.toBase64(key);

    // Obtener el objeto almacenado
    const result = await get(encodedKey);

    if (result) {
      // Decodificar el valor del objeto
      const decodedValue = this.fromBase64(result.value);

      // Intentar parsear el valor como JSON
      try {
        return JSON.parse(decodedValue);
      } catch {
        // Si el valor no es un JSON válido, devolver el valor decodificado como está
        return decodedValue;
      }
    }

    return undefined;
  }

  // public set(key: string, value: any) {
  //     return set(key, { value, timestamp: new Date().getTime(), version: 1, });
  // }

  // Almacenar datos en IndexedDB con codificación en base64
  public async set(key: string, value: any): Promise<void> {
    // Convertir el valor a JSON si es un objeto
    const jsonValue = typeof value === "object" ? JSON.stringify(value) : value;

    // Codificar la clave y el valor en base64
    const encodedKey = this.toBase64(key);
    const encodedValue = this.toBase64(jsonValue);

    // Almacenar en IndexedDB
    await set(encodedKey, {
      value: encodedValue,
      timestamp: new Date().getTime(),
      version: 1,
    });
  }

  public remove(key: string) {
    return del(key);
  }
}
