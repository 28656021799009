import { ApiClient } from "./ApiClient";
import { environment } from "@/environments/environment.dev";
import { environment as environ } from "../../environments/environment.dev";

class ServiceOrchestrator extends ApiClient {

    public async getAssets(query: string) {
        try {

            let response = await this.apiClientGet(
                environ.url_servers.url_orchertractor,
                "assetsListBinance",
                query
            )

            return response;

        } catch (error) {
            throw error;
        }

    }

    public async postTranferSubCuentas(body: unknown) {

        try {

            let response = await this.apiClientPostAll(
                environ.url_servers.url_orchertractor,
                "transferAssetsSubAccountBinance",
                body
            )

            return response;

        } catch (error) {
            throw error;
        }

    }

    public async getOrderVankPay(query: string) {
        try {

            let response = await this.apiClientGet(
                environ.url_servers.url_orchertractor,
                "getHistoryOrderVankpaySubAccouts",
                query
            )
                        
            return response;

        } catch (error) {
            throw error;
        }
    }

    public async getCryptos(query: string) {
        try {

            let response = await this.apiClientGet(
                environ.url_servers.url_orchertractor,
                "assetsListBinance",
                query
            )
            return response;

        } catch (error) {
            throw error;
        }
    }

    public async updateOrderVankPayUsers(body: unknown) {
        try {

            let response = await this.apiClientPutAll(
                environ.url_servers.url_orchertractor,
                "updateOrderVankPayUsers",
                body
            )

            return response;

        } catch (error) {
            throw error;
        }
    }

    public async getAddQR(query: string) {
        try {

            let response = await this.apiClientGet(
                environ.url_servers.url_orchertractor,
                "getDepositAddressBinance",
                query
            )

            return response;

        } catch (error) {
            throw error;
        }

    }

    public async getQrVankPay(query: string) {
        try {

            let response = await this.apiClientGet(
                environ.url_servers.url_orchertractor,
                "getQrVankPay",
                query
            )

            return response;

        } catch (error) {
            throw error;
        }
    }

}

export const serviceOrchestrator = new ServiceOrchestrator();