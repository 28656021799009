import { Outlet, useLocation } from "react-router-dom";
import { Sidebar } from "../../Shared/layout/Sildebar/Sidebar";
import { Suspense, useEffect, useState } from "react";
import RootVankSkeleton from "./RootVankSkeleton";
import SendModal from "./SendModal/SendModal";
import ReceiveModal from "./ReceiveModal/ReceiveModal";
import SidebarNotifications from "@/apps/Shared/layout/Header/UserProfileAndNotifications/SidebarNotifications/SidebarNotifications";
import SidebarProfile from "@/apps/Shared/layout/Header/UserProfileAndNotifications/SidebarProfile/SidebarProfile";
import { CacheDropDowns } from "@/apps/Shared/infrastructura/Persistence/CacheDropDowns";
import CustomModalErrorHttp from "@/apps/Shared/CustomModalErrorHttp/CustomModalErrorHttp";
import './RootVank.css'
import { useTranslation } from "react-i18next";

export const RootVank = () => {

  const cacheDropdowns = new CacheDropDowns();
  const [isOpenSend, setIsOpenSend] = useState(false)
  const [isOpenReload, setIsOpenReload] = useState(false)
  const location = useLocation()
  //Estado para la función de traducción
  const [t] = useTranslation("global");

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState({
    message: '',
    status: ''
  });

  useEffect(() => {
    setIsLoading(true);

    cacheDropdowns
      .getUser()
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setIsOpen(true)
        // console.log(e.message)
        if (e.message === 'session-expired') {
          setErrorMessage((prevState: any) => ({
            ...prevState,
            message: t("RootVank.RootVank.text1"),
            status: "401",
          }));
          return
        }
        setErrorMessage((prevState: any) => ({
          ...prevState,
          message: t("RootVank.RootVank.text2"),
          status: "401",
        }));
      });

    // document.addEventListener("session-expired", () => setOpenDrop(true));
  }, []);

  useEffect(() => {
    document.addEventListener('SendModal', () => setIsOpenSend(true))
    document.addEventListener('ReceiveModal', () => setIsOpenReload(true))
  }, [])


  return (
    <>
      {isLoading ? (
        <div className="w-full h-screen bg-[--color-lighter-white] justify-center items-center inline-flex overflow-hidden">
          <svg className="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
            <circle
              className="active"
              pathLength="360"
              fill="transparent"
              stroke-width="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
            <circle
              className="track"
              pathLength="360"
              fill="transparent"
              stroke-width="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
          </svg>
        </div>
      ) : (
        <Suspense fallback={<RootVankSkeleton />}>
          <div className="relative w-full h-screen bg-[--color-lighter-white] justify-start items-center inline-flex overflow-hidden">
            <Sidebar setIsOpenSend={setIsOpenSend} setIsOpenReload={setIsOpenReload} />

            <main className="relative w-full h-screen overflow-auto">
              <Outlet />
            </main>

            <SidebarNotifications />
            <SidebarProfile />

            <SendModal isOpenSend={isOpenSend} setIsOpenSend={setIsOpenSend} />
            <ReceiveModal isOpenReload={isOpenReload} setIsOpenReload={setIsOpenReload} />

          </div>
          <CustomModalErrorHttp isOpen={isOpen} setIsOpen={setIsOpen} message={errorMessage} />
        </Suspense >
      )}
    </>
  );
};
