import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
export const AlertCopied = ({ moreStyle }: { moreStyle?:string }) => {
    const [t] = useTranslation("global");
    return (
        <motion.div

            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            transition={{ duration: 0.5 }}
            className={`absolute ${moreStyle} bg-[#80808088] font-bol  px-2 text-xs rounded-lg text-white `}
        >
            {t("Transactions.AlertCopied.AlertCopied.Copied")}
        </motion.div>
    )
}
