import { AxiosClientRepository } from "@/apps/Shared/infrastructura/Http/AxiosHttp";
import { environment } from "@/environments/environment.dev";
import { ServiceDecodiToken } from "../../VankPay/service/ServiceDecodiToken";

export class ServicesPrintsFiat extends AxiosClientRepository {
  public async getPrintsFiat(data: any) {
    const serviceToken = new ServiceDecodiToken();

    const email = await serviceToken.getEmail();
    const virtual = await serviceToken.getVirtualEmail();

    try {
      const url =
        `${environment.url_servers.url_prints}/prints/postPrintsPayment?query=` +
        JSON.stringify({
          CODERECEIPT: data.CODERECEIPT,
          ACTION: data.ACTION,
          GENERATEDBY: virtual,
          URL: data.URL,
          TO_EMAIL: data.TO_EMAIL,
          EMISOR: email,
        });
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  // public async shareLinkFeature(data:any){
  //   try {
  //     const url = `${environment.url_servers.url_orchertractor}/pdfUrl`;
  //     let response = await this.post({ url ,data});
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
}
