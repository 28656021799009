import React from 'react'
import './CustomNotification.css'
import Check2 from '@/assets/Icon/Check2'
import Close from '@/assets/Icon/Close';
import Error from '@/assets/Icon/Error';
import Copy from '@/assets/Icon/Copy';



const CustomNotification = ({
    showModal,
    setShowModal,
}: {
    showModal: {
        status: string;
        message: string;
        show: boolean;
    };
    setShowModal: any;
}) => {

    const getStatusIcon = (status: string) => {
        return status === 'ERROR' ? <Error
            className="w-[23px] h-[23px]"
            fill="white" />
            : status === 'SUCCESS' ? <Check2
                className="w-[23px] h-[23px]"
                fill="white" />
                : status === 'COPY' && <Copy className="w-[25px] h-[25px]"
                    fill="white" />;
    };


    return (
        <div className={`toast ${showModal.show && 'active'} ${showModal.status === 'ERROR' ? 'error' : (showModal.status === 'SUCCESS' || showModal.status === 'COPY') && 'success'} z-50`}>
            {/* icono close*/}
            <div className='toast-content '>
                {/* icono check*/}
                <div className={`w-[64px] h-[56px] flex justify-center items-center`}>
                    <div className={`min-w-[40px] min-h-[40px] flex justify-center items-center ${showModal.status === 'ERROR' ? 'bg-red-500' : (showModal.status === 'SUCCESS' || showModal.status === 'COPY') && 'bg-[var(--branding-brand-yellow-light)]'} rounded-full`}>
                        {getStatusIcon(showModal.status)}
                    </div>
                </div>
                <div className='message'>
                    {/* <span className='text text-1'>{showModal.status}</span> */}
                    <span className='text text-2'>{showModal.message}</span>
                </div>
            </div>

            <div className={`progress ${showModal.show && 'active'} 
            ${showModal.show && showModal.status === 'ERROR' ? 'error' : showModal.show && (showModal.status === 'SUCCESS' || showModal.status === 'COPY') && 'success'}
            `} />
            <button className='close' onClick={() => {
                setShowModal((prevState: any) => ({
                    ...prevState,
                    show: false,
                }));
            }}>
                <Close fill="var(--content-main-gray-light)" />
            </button>
        </div>
    )
}

export default CustomNotification