import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import {
  IconClose,
  IconCrypto,
  IconDolar,
  IconVVankPay,
} from "@/assets/IconV2/IconsSvg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SendModal = ({
  isOpenSend,
  setIsOpenSend,
}: {
  isOpenSend: any;
  setIsOpenSend: any;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverPay, setIsHoverPay] = useState(false);
  const [isHoverCrypto, setIsHoverCrypto] = useState(false);
  const [isHoverDolar, setIsHoverDolar] = useState(false);
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  const handleMouseHoverClose = (hovered: boolean) => {
    setIsHovered(hovered);
  };



  const handleMouseHoverPay = (hovered: boolean) => {
    setIsHoverPay(hovered);
  };

  const handleMouseHoverCrypto = (hovered: boolean) => {
    setIsHoverCrypto(hovered);
  };

  const handleMouseHoverDolar = (hovered: boolean) => {
    setIsHoverDolar(hovered);
  };


  const handleNavigate = (path: string) => {
    setIsOpenSend(false)
    setIsHovered(false)
    setIsHoverPay(false)
    setIsHoverCrypto(false)
    setIsHoverDolar(false)
    setTimeout(() => {
      navigate(path)
    }, 70);
  }

  return (
    <CustomModal isOpen={isOpenSend}>
      <div className="2xl:w-[550px] xl:w-[550px] lg:w-[550px] md:w-[550px] sm:w-[550px] 2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute mobile:bottom-0    mobile:w-full h-[282px] px-4 py-6 bg-[var(--bacground-component-module-light)] rounded-2xl flex-col justify-center items-center gap-6 inline-flex">
        <div className="2xl:w-[518px] xl:w-[518px] lg:w-[518px] md:w-[518px] sm:w-[518px] mobile:w-full h-12 flex-col justify-start items-start inline-flex">
          <div className="w-full h-7 justify-between items-center inline-flex ">
            <span className="w-full text-[var(--content-main-black-light)] 2xl:text-[22px] xl:text-[22px] lg:text-[22px] md:text-[22px] sm:text-[22px] mobile:text-[18px] font-semibold">{t("Vank.Share.Sidebar.SendModal.title")}</span>
            <div className="w-7 p-0 2xl:border-none xl:border-none lg:border-none md:border-none sm:border-none mobile:border border-[#818282] h-7 rounded-[500px] justify-center items-center gap-2.5 flex hover:bg-[var(--content-main-black-light)] transition-all duration-500 cursor-pointer"
              onMouseEnter={() => handleMouseHoverClose(true)}
              onMouseLeave={() => handleMouseHoverClose(false)}
              onClick={() => {
                setIsOpenSend(false)
                handleMouseHoverClose(false)
              }}
            >
              <IconClose className="w-full h-full transition-all duration-500" fill={isHovered && 'white'} />
            </div>
          </div>
          <span className="text-start text-[var(--content-main-black-light)] text-sm font-normal leading-tight">{t("Vank.Share.Sidebar.SendModal.message")}</span>
        </div>

        <div className="  justify-start items-center gap-4 inline-flex">

          <div className="group w-[162px] text-center h-[162px] py-10  bg-[var(--branding-brand-yellow-light)] text-[var(--content-inner-inner-black-light)] hover:bg-[var(--content-inner-inner-black-light)] hover:text-[var(--branding-brand-yellow-light)] rounded-2xl flex-col justify-center items-center gap-3 inline-flex transition-all duration-300 cursor-pointer"
            onMouseEnter={() => handleMouseHoverPay(true)}
            onMouseLeave={() => handleMouseHoverPay(false)}
            onClick={() => handleNavigate('/send/vankpay')}
          >
            <div className='flex flex-col gap-3 group-hover:h-24 group-hover:gap-2 '>
              <div className=" w-[84px]  h-[84px] flex justify-center items-center  ">
                <IconVVankPay className="w-full group-hover:h-14 h-full " fill={isHoverPay && 'var(--branding-brand-yellow-light)'} />
              </div>
              <div className="text-center text-lg font-semibold ">{t("Vank.Share.Sidebar.SendModal.Actions.VankPay.title")}</div>
            </div>


            <span className='group-hover:block px-[5px] hidden text-xs'>{t("Vank.Share.Sidebar.SendModal.text1")}</span>
          </div>

          {/* <div className="group w-[162px] h-[162px] text-center bg-[var(--branding-brand-yellow-light)] text-[var(--content-inner-inner-black-light)] hover:bg-[var(--content-inner-inner-black-light)] hover:text-[var(--branding-brand-yellow-light)] rounded-2xl flex-col justify-center items-center gap-3 inline-flex transition-all duration-500 cursor-pointer"
            onMouseEnter={() => handleMouseHoverCrypto(true)}
            onMouseLeave={() => handleMouseHoverCrypto(false)}
            onClick={() => handleNavigate('/send/crypto')}
          >
            <div className='flex flex-col gap-3 group-hover:h-24 group-hover:gap-2'>

              <div className="w-[84px] h-[84px] flex justify-center items-center ">
                <IconCrypto className="w-full h-full group-hover:h-14 " stroke={isHoverCrypto && 'var(--branding-brand-yellow-light)'} />
              </div>
              <div className="text-center text-lg font-semibold ">{t("Vank.Share.Sidebar.SendModal.Actions.Crypto.title")}</div>

            </div>

            <span className='group-hover:block px-[5px] hidden text-xs'>{t("Vank.Share.Sidebar.SendModal.text2")}</span>

          </div> */}

          <div className="group w-[162px] h-[162px] text-center bg-[var(--branding-brand-yellow-light)] text-[var(--content-inner-inner-black-light)] hover:bg-[var(--content-inner-inner-black-light)] hover:text-[var(--branding-brand-yellow-light)] rounded-2xl flex-col justify-center items-center gap-3 inline-flex transition-all duration-500 cursor-pointer"
            onMouseEnter={() => handleMouseHoverDolar(true)}
            onMouseLeave={() => handleMouseHoverDolar(false)}
            onClick={() => handleNavigate('/send/fiat')}
          >
            <div className='flex  flex-col gap-3 group-hover:h-24 group-hover:gap-2'>
              <div className="w-[84px] h-[84px] flex justify-center items-center ">
                <IconDolar className="w-full h-full group-hover:h-14" stroke={isHoverDolar && 'var(--branding-brand-yellow-light)'} />
              </div>
              <div className="text-center text-lg font-semibold ">{t("Vank.Share.Sidebar.SendModal.Actions.Fiat.title")}</div>
            </div>
            <span className='group-hover:block px-[5px]  hidden text-xs'>{t("Vank.Share.Sidebar.SendModal.text3")}</span>
          </div>

        </div>
     </div>
  </CustomModal>
        );
};

        export default SendModal;
