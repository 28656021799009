import { RouterProvider } from "react-router-dom";
import { Context } from "@redtea/react-inversify";
import router from "./Router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { CacheDropDowns } from "./apps/Shared/infrastructura/Persistence/CacheDropDowns";
import { useEffect, useState } from "react";
import ErrorInfoModal from "./apps/Auth/ErrorInfoModal/ErrorInfoModal";
import CustomModal, { backStyle } from "./apps/Shared/CustomModal/CustomModal";
import { AnimatePresence, motion } from "framer-motion";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import pulse1 from "@assets/img/pulse1.gif";
import ToastContainer from "./apps/Shared/ToastContainer/ToastContainer";

function App({ container }) {
  const cacheDropdowns = new CacheDropDowns();
  const [modalState, setModalState] = useState({
    title: "Aviso de Móvil",
    message:
      "La página no está completamente optimizada para móviles aún. Mientras tanto, puedes continuar con tu proceso o recorrido en la web sin problemas. Gracias por tu paciencia.",
    show: false,
    url: "hide",
  });
  const [isMobil, setIsMobil] = useState(window.innerWidth <= 768); // Puedes ajustar el umbral

  useEffect(() => {
    if (!localStorage.getItem("lenguaje")) {
      localStorage.setItem("lenguaje", "es");
    }

    cacheDropdowns
      .cache()
      .then(() => {
        // setIsLoading(false);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    // /Android.*Mobile/: Detecta dispositivos Android que específicamente tienen la palabra "Mobile" en el userAgent, que generalmente indica un teléfono móvil (no una tableta).
    // /iPhone|iPod|Windows Phone: Detecta iPhones, iPods y dispositivos con Windows Phone.
    const currentPath = window.location.pathname.toLocaleLowerCase();
    if (isMobile && isMobil) {
      // console.log("Estás usando un dispositivo móvil!!");
      if (currentPath === "/auth/AddPhone".toLocaleLowerCase()) {
        setModalState((prevState: any) => ({
          ...prevState,
          show: true,
          message:
            "La página no está completamente optimizada para móviles aún. Para que sigas el proceso de registro, continúa en la web. Gracias por tu comprensión.",
        }));
        return;
      }

      setModalState((prevState: any) => ({
        ...prevState,
        show: true,
      }));
    }
  }, []);

  return (
    <Context.Provider value={container}>
      <RouterProvider router={router} />
      {/* <AnimatePresence>
        {modalState?.show && (
          <motion.div
            onClick={(e) => e.stopPropagation()}
            className="bg-[--color-lighter-white] fixed inset-0 z-50 flex justify-center items-center overflow-hidden px-3"
            initial={backStyle.initial}
            animate={backStyle.animate}
            exit={backStyle.exit}
          >
            <motion.div
              onClick={(e) => e.stopPropagation()}
              className="relative max-w-[400px] w-full max-h-max bg-white cursor-default flex flex-col rounded-[24px] pt-5 shadow"
              initial={{
                scale: 0,
              }}
              animate={{
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                scale: 0,
              }}
            >
              <img
                className="w-30 h-[100px] object-cover absolute left-1/2 -translate-x-1/2 -top-12"
                src={pulse1}
              />
              <div className="w-full h-full py-7 flex flex-col items-center gap-6">
                <div className="flex flex-col justify-center items-center gap-3">
                  <h2 className="text-center text-2xl font-bold mb-3 px-5">
                    {modalState?.title}
                  </h2>
                  <p className="w-full text-center text-lg leading-normal font-medium px-6">
                    {modalState?.message}{" "}
                  </p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        ) : (
          <RouterProvider router={router} />
        )}
      </AnimatePresence> */}
    </Context.Provider>
  );
}

export default App;
