import { IconClose, IconTooltip } from "@/assets/IconV2/IconsSvg";
import React, { useState } from "react";
import './style.css'
import { Tooltip } from "react-tooltip";


export const HeaderModal = ({
    classNameTexts,
    headerStyle,
    textStyle,
    onClick,
    text,
    subtext,
    headerHeight = "h-[58px]",
    width = "w-[553.58px]",
    subtextStyle
}: {
    onClick?: any;
    text?: string;
    subtext?: string;
    headerHeight?: string;
    width?: string;
    textStyle?: string;
    headerStyle?: string;
    classNameTexts?: string;
    subtextStyle?: string
}) => {
    const [fill, setFill] = useState<string>("var(--content-main-gray-light)");

    return (
        <header className={` flex justify-between ${headerStyle}  ${headerHeight} ${width}`}>
            <div className={`${classNameTexts} `}>
                <p className={`text-[20px]  text-[var(--content-main-black-light)] flex items-center ${textStyle}`}>{text}
                    <span className="ml-1 flex">
                        <Tooltip
                            anchorSelect="#my-anchor-element"
                            place="right"
                            style={{
                                width: "300px",
                                borderRadius: "4px",
                                background: "#F8F9F9",
                                color: "#2D2E2F",
                                boxShadow: "-2px 1px 10px 0px rgba(0, 0, 0, 0.07)",
                            }}
                            border="1px solid #C0C0C1"
                        >
                        </Tooltip>
                        <IconTooltip/>
                    </span>
                </p>
                <p className={`subtext ${subtextStyle}`}>
                    {subtext}
                </p>

            </div>

            <div onMouseEnter={() => setFill('var(--content-main-white-light)')} onMouseLeave={() => setFill('var(--content-main-gray-light)')} onClick={onClick} className={` h-8 w-8 cursor-pointer rounded-full hover:bg-[var(--content-main-black-light)] `}>
                <IconClose fill={fill} />
            </div>

        </header>
    )
}
