import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import './CustomModal.css'

export const backStyle = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const CustomModal = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: any;
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          //   onClick={closeModalForm}
          className="Backdrop fixed inset-0 z-50 flex justify-center items-center overflow-hidden"
          initial={backStyle.initial}
          animate={backStyle.animate}
          exit={backStyle.exit}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CustomModal;
