import { atom } from "recoil";

export const postUpdateConvert=atom({
    key:"updateConvertVankpay",
    default:{
        valueConvert:""
    }
})

export const postTokenVirtualEmail=atom({
    key:"postTokenVirtualEmail",
    default:{
        GENERATEDBY:""
    }
})

