import { environment } from "@/environments/environment.dev";
import { AxiosClientRepository } from "../Shared/infrastructura/Http/AxiosHttp";

export class ServicesUser extends AxiosClientRepository {
  async getUser() {
    try {
      const url = `${environment.url_servers.url_users}/secure/v1/profileusers`;
      const response = await this.get({ url });
      console.log(response)
      return response.body;
    } catch (error) {
      console.log(error)
      throw error;
    }
  }
}
