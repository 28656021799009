import { environment } from "@/environments/environment.dev";
import { AxiosClientRepository } from "../../Http/AxiosHttp";

export class Services extends AxiosClientRepository {
  async getLibraryCurrency() {
    try {
      const url = `${environment.url_servers.url_library}/libraryName/COUNTRY`;
      const response = await this.get({ url });
      console.log(response.body);
      return response.body;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async getLibraryWallet() {
    try {
      const url = `${environment.url_servers.url_library}/libraryName/CRYPTO`;
      const response = await this.get({ url });
      console.log(response.body);
      return response.body;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  
  async getLibraryRegion() {
    try {
      const url = `${environment.url_servers.url_library}/libraryName/REGION_DATA_VIEW`;
      const response = await this.get({ url });
      console.log(response.body);
      return response.body;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
