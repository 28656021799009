import React from "react";
import Cards from "@assets/img/imgCard/Cards.png";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";


const NextView = ({ setShow }: { setShow: any }) => {
  const [t,i18n] = useTranslation("global");
    return (
        <motion.div className='w-full gap-6  2xl:mb-[0rem] xl:mb-[0rem] lg:mb-[0rem] md:mb-[0rem] sm:mb-[4rem] mobile:mb-[4rem]   bg-[var(--bacground-component-module-light)] rounded-2xl
            2xl:flex xl:flex lg:flex md:flex sm:flex mobile:flex 2xl:flex-col xl:flex-col lg:flex-col md:flex-col sm:flex-col mobile:flex-row 
            2xl:h-[324px] xl:h-[324px] lg:h-[324px] md:h-[324px] sm:h-[324px] 
            2xl:p-4 xl:p-4 lg:p-4 md:p-4 sm:p-4 mobile:py-4
            justify-between items-center p-4'
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
            transition={{ duration: 0.3 }}
        >
            <div className='w-full    min-h-[17px] justify-between items-center flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row mobile:flex mobile:flex-col mobile:items-start'>
                <span className="text-[var(--content-main-black-light)] leading-[20px] 2xl:min-w-[227px] xl:min-w-[227px] lg:min-w-[227px] md:min-w-[227px] sm:min-w-[227px]  h-full text-base font-semibold cursor-pointer z-[8]"
                    onClick={() => setShow(false)}>
                    {t("Vank.Home.ManageCards.NextView.VankCards")}
                    </span>
                <span className="text-[var(--content-main-gray-light)]  2xl:text-sm xl:text-sm lg:text-sm md:text-sm sm:text-sm 2xl:font-normal xl:font-normal lg:font-normal md:font-normal sm:font-normal tracking-tight mobile:text-[24px] mobile:font-semibold">2024</span>
                <div className="flex items-center justify-center  mt-2 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden mobile:flex">
                    <span className='z-10 border-b border-b-[#818282] text-sm font-semibold text-[#818282]'>{t("Home.ManageCards.NextView.NextView.view")}</span>
                    <div className="w-[16px] h-[16px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M10.8267 5.17383L5.17334 10.8272M10.8267 5.17383H7.43334M10.8267 5.17383V8.56716" stroke="#818282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className='relative  2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full 2xl:h-[260px] xl:h-[260px] lg:h-[260px] md:h-[260px] sm:h-[260px] 2xl:pb-[16px] xl:pb-[16px] lg:pb-[16px] md:pb-[16px] sm:pb-[16px] mobile:pb-0 mobile:w-[150px] mobile:h-full  flex justify-center items-end rounded-[12px] border border-[var(--strokes-separator-separator-light)] bg-[var(--background-layaout-light)]'>
                <div className='2xl:absolute xl:absolute lg:absolute md:absolute sm:absolute top-[-31px]
                    2xl:min-w-[454.217px] xl:min-w-[454.217px] lg:min-w-[454.217px] md:min-w-[454.217px] sm:min-w-[454.217px] mobile:min-w-[209px]
                    2xl:min-h-[254.275px] xl:min-h-[254.275px] lg:min-h-[254.275px] md:min-h-[254.275px] sm:min-h-[254.275px] mobile:h-full'>
                    <img src={Cards} alt="" className="" />
                </div>
                <span className='z-10 uppercase 2xl:block xl:block lg:block md:block sm:block mobile:hidden text-sm font-semibold text-[var(--content-main-black-light)]'>{t("Vank.Home.ManageCards.NextView.Comingsoon")}</span>
            </div>
        </motion.div>
    )
}

export default NextView;
