import { atom } from "recoil";

export const showBalanceState = atom({
  key: "showBalanceState",
  default: true, // Mostrar el saldo de forma predeterminada
});

export const currenciesState = atom({
  key: "currenciesState",
  default: {
    // currencies: [], // Inicialmente, el array estará vacío hasta que se carguen los datos desde la API
    selectCurrency: {
      name: "",
      balance: "",
      amounts: {
        accounts: "",
        vankCard: "",
        wallet: "",
      },
    }, // Inicialmente, el array estará vacío hasta que se carguen los datos desde la API
    showBalance: true, // Mostrar el saldo de forma predeterminada
  },
});
