import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const formtDate = (date: string) => {
    return new Date(date)
    .toLocaleString('es-ES', {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Cambia a true para formato de 12 horas con AM/PM
    })
    .replace(',', ', ')
    .replace(' a. m.', ' a.m')
    .replace(' p. m.', ' p.m')
    // + ' (UTC-5)';
};




// Función para formatear la fecha en la zona horaria detectada automáticamente
const formatDateP = (date: Date | string, timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
  return formatInTimeZone(new Date(date), timeZone, 'yyyy-MM-dd hh:mm aaaa');
};


export {formtDate,formatDateP}
