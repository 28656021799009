import React from 'react'
import ModelInfoCurrencie from '../../models/ModelInfoCurrencie'
import { HeaderModal } from '../../../Modales/HeaderModal/HeaderModal'
import './styles.css'
import { ButtonCopy } from '@/apps/Vank/Page/Transactions/shared/ButtonCopy/ButtonCopy'
import { formatBalance } from '../../../Home'
import { useTranslation } from 'react-i18next'
const ModalInfoCurrencie = ({ data, onclickHeader }: { data: ModelInfoCurrencie, onclickHeader: () => void }) => {

    const [t, i18n] = useTranslation("global");


    return (
        <div className='bg-[var(--bacground-component-module-light)] min-w-[422px] rounded-2xl py-4 flex flex-col gap-4'>
            <div className='w-full px-4'>
                <HeaderModal headerHeight='' headerStyle='w-full' text={t('Vank.Home.Balances.AccountDetails.Title')} onClick={onclickHeader} />
            </div>
            <hr />
            <div className='flex flex-col px-4 gap-2'>
                <div className='item-content'>
                    <span className='item-th'>{t('Vank.Home.Balances.AccountDetails.item1')}:</span>
                    <span className='item-td'>{data?.acount}</span>
                </div>
                <div className='item-content'>
                    <span className='item-th'>{t('Vank.Home.Balances.AccountDetails.item2')}</span>
                    <span className='item-td'>{data?.acount == "COP" ? "Pesos" : "Dolares"}</span>
                </div>
                <div className='item-content'>
                    <span className='item-th'>{t('Vank.Home.Balances.AccountDetails.item3')}</span>
                    <span className='item-td'>{formatBalance(parseFloat(data?.balance))}</span>
                </div>
                <div className='item-content'>
                    <span className='item-th'>{t('Vank.Home.Balances.AccountDetails.item4')}</span>
                    <span className='item-td w-5'><img src={data?.typeAcount} alt="" /></span>
                </div>
                <div className='item-content'>
                    <span className='item-th'>{t('Vank.Home.Balances.AccountDetails.item5')}</span>
                    <div className='flex items-center'>
                        <span className='item-td'>{data?.acountNumer}</span>
                        <span><ButtonCopy textCopy={data?.acountNumer}/></span>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ModalInfoCurrencie
