import es from "@assets/i18n/es.json";
import en from "@assets/i18n/en.json";
import pt from "@assets/i18n/pt.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";

i18n
  .use(LocalStorageBackend) // Persistir idiomas en el localStorage
  .use(LanguageDetector) // Detecta el idioma del navegador
  .use(initReactI18next) // Pasar i18n a react-i18next
  .init({
    fallbackLng: "en", // Idioma por defecto si el detectado no está disponible
    supportedLngs: ["en", "es", "pt"], // Idiomas soportados
    backend: {
      // Configuración del backend localStorage
      expirationTime: 7 * 24 * 60 * 60 * 1000, // 1 semana
    },
    detection: {
      order: ["localStorage", "navigator"], // Orden de detección del idioma
      caches: ["localStorage"], // Cachear el idioma en el localStorage
    },
    interpolation: {
      escapeValue: false, // React ya protege contra XSS
    },
    resources: {
      es: {
        global: es,
      },
      en: {
        global: en,
      },
      pt: {
        global: pt,
      },
    },
  });

export default i18n;
