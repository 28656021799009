import React from "react";

const Close = ({
  className,
  fill = "#97CB0A",
  onClick,
}: {
  className?: any;
  fill?: any;
  onClick?: () => void;
}) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M0.33546 0.83546C0.550319 0.620665 0.841693 0.5 1.1455 0.5C1.44932 0.5 1.74069 0.620665 1.95555 0.83546L6.00692 4.88683L10.0583 0.83546C10.2744 0.626752 10.5638 0.511267 10.8642 0.513878C11.1646 0.516488 11.452 0.636985 11.6644 0.849417C11.8768 1.06185 11.9973 1.34922 12 1.64963C12.0026 1.95004 11.8871 2.23946 11.6784 2.45555L7.62701 6.50692L11.6784 10.5583C11.8871 10.7744 12.0026 11.0638 12 11.3642C11.9973 11.6646 11.8768 11.952 11.6644 12.1644C11.452 12.3768 11.1646 12.4973 10.8642 12.5C10.5638 12.5026 10.2744 12.3871 10.0583 12.1784L6.00692 8.12701L1.95555 12.1784C1.73946 12.3871 1.45004 12.5026 1.14963 12.5C0.849216 12.4973 0.561848 12.3768 0.349417 12.1644C0.136985 11.952 0.0164882 11.6646 0.0138777 11.3642C0.0112672 11.0638 0.126752 10.7744 0.33546 10.5583L4.38683 6.50692L0.33546 2.45555C0.120665 2.24069 0 1.94932 0 1.6455C0 1.34169 0.120665 1.05032 0.33546 0.83546Z"
        fill={fill ? fill : "#A7A7AF"}
      />
    </svg>
  );
};

export default Close;
