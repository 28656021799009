import React, { createContext, useContext, useState } from "react";
import ToastContainer from "@/apps/Shared/ToastContainer/ToastContainer";
import { Toast } from "./types";

// Crear los contextos de estado y dispatch
const ToastStateContext = createContext([]);
const ToastDispatchContext = createContext(null);

// Proveedor de contexto para los toasts
const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const [position, setPosition] = useState("top-right");

  // Función para agregar un nuevo toast
  const addToast = (toast: Toast) => {
    if (toast.position && toast.position !== position) {
      setPosition(toast.position);
    }

    setToasts((prevToasts) => [
      ...prevToasts,
      { ...toast, show: true, progress: 0 },
    ]);
  };

  // Función para eliminar un toast por id
  const deleteToast = ({ id, newProgress }) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Función para actualizar un toast por id
  const updateToast = (updatedToast) => {
    console.log(updatedToast.type);
    
    setToasts((prevToasts) =>
      prevToasts.map((toast) =>
        toast.id === updatedToast.id ? { ...toast, ...updatedToast } : toast
      )
    );
  };

  return (
    <ToastStateContext.Provider value={[toasts, setToasts]}>
      <ToastDispatchContext.Provider
        value={{ addToast, deleteToast, updateToast }}
      >
        {children}
        <ToastContainer position={position} />
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
};

// Hooks personalizados para acceder a los contextos
export const useToastState = () => {
  const context = useContext(ToastStateContext);
  if (context === undefined) {
    throw new Error("useToastState debe ser usado dentro de un ToastProvider");
  }
  return context;
};

export const useToastDispatch = () => {
  const context = useContext(ToastDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useToastDispatch debe ser usado dentro de un ToastProvider"
    );
  }
  return context;
};

export default ToastProvider;

// import ToastContainer from "@/apps/Shared/ToastContainer/ToastContainer";
// import { createContext, useContext, useReducer } from "react";

// const ToastStateContext = createContext({ toasts: [] });
// const ToastDispatchContext = createContext(null);

// // Define your action types
// const actionTypes = {
//   ADD_TOAST: "ADD_TOAST",
//   DELETE_TOAST: "DELETE_TOAST",
//   UPDATE_TOAST: "UPDATE_TOAST",
// };

// const ToastReducer = (state, action) => {
//   switch (action.type) {
//     case "ADD_TOAST":
//       return {
//         ...state,
//         toasts: [...state.toasts, action.toast],
//       };
//     case "DELETE_TOAST": {
//       return {
//         ...state,
//         toasts: state.toasts.filter((toast) => toast.id !== action.id),
//       };
//     }
//     case "UPDATE_TOAST": {
//       return {
//         ...state,
//         toasts: state.toasts.map((toast: any) =>
//           toast.id === action.toast.id ? { ...toast, ...action.toast } : toast
//         ),
//       };
//     }
//     default:
//       return state;
//   }
// };

// const ToastProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(ToastReducer, {
//     toasts: [],
//   });

//   console.log(state);

//   return (
//     <ToastStateContext.Provider value={state}>
//       <ToastDispatchContext.Provider value={dispatch}>
//         {children}
//         <ToastContainer />
//       </ToastDispatchContext.Provider>
//     </ToastStateContext.Provider>
//   );
// };

// export default ToastProvider;

// export const useToastStateContext = () => useContext(ToastStateContext);
// export const useToastDispatchContext = () => useContext(ToastDispatchContext);

// export const useToastState = () => {
//   const context = useContext(ToastStateContext);
//   if (context === undefined) {
//     throw new Error("useToastState must be used within a ToastProvider");
//   }
//   return context;
// };

// export const useToastDispatch = () => {
//   const context = useContext(ToastDispatchContext);
//   if (context === undefined) {
//     throw new Error("useToastDispatch must be used within a ToastProvider");
//   }
//   return context;
// };
