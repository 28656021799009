import React from "react";

const HighlightWords = (
  text: any | string,
  wordsToHighlight: any[],
  className?: string
) => {
  // Escapa los caracteres especiales en las palabras a resaltar para usar en una expresión regular.
  const escapedWordsToHighlight = wordsToHighlight.map((word) =>
    word.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
  );

  // Crea una expresión regular para buscar todas las palabras a resaltar dentro del texto.
  const regex = new RegExp(`(${escapedWordsToHighlight.join("|")})`, "gi");

  // Divide el texto en partes utilizando la expresión regular como separador y filtra las partes para eliminar las cadenas vacías.
  const parts = text.split(regex).filter((part) => part !== "");

  const _className = `${className ? className : "font-bold"}`;

  return (
    <>
      {parts.map(
        (
          part: string,
          index: number // Mapea sobre las partes del texto y devuelve un elemento `<span>` para cada parte.
        ) => (
          <span key={index}>
            {wordsToHighlight.includes(part.toLowerCase()) ? ( // Verifica si la palabra actual está en `wordsToHighlight` después de convertirla a minúsculas.
              <span className={_className}>{part}</span> // Si la palabra está en `wordsToHighlight`, se envuelve en un elemento `<span>` con la clase `_className`; de lo contrario, se muestra la palabra normalmente.
            ) : (
              part
            )}
          </span>
        )
      )}
    </>
  );
};

export default HighlightWords;
