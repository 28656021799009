import React, { useEffect, useState } from 'react'
import { ServiceDecodiToken } from '../../Transactions/VankPay/service/ServiceDecodiToken';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { postTokenVirtualEmail } from '../../Transactions/VankPay/components/FormTransaction/atoms/postUpdateConvert';
import { useNavigate } from 'react-router-dom';
import { IconArrowSend } from '@/assets/IconV2/IconsSvg';
import UserProfileAndNotifications from '@/apps/Shared/layout/Header/UserProfileAndNotifications/UserProfileAndNotifications';
import { ArrowHide } from '@/assets/Icon/ArrowHide';

const HeaderPage = ({ title, subtext, buttonRechage, visibleItem }: { title?: string, subtext?: string, buttonRechage?: boolean, visibleItem: boolean }) => {
    //stado para ver el modal Qr

    const serviceToken = new ServiceDecodiToken();
    const [isActive, setIsActive] = useState(true);
    const [t, i18n] = useTranslation("global");
    const [dataToken, setDataTokent] = useState<any>({})
    const [viewModelQR, setViewModelQR] = useState<boolean>(false)
    const [colorStroke, setColorStroke] = useState<string>("#14181F")
    const [getTokenValueVitualEmail, setGetTokenValueVitualEmail] = useRecoilState(postTokenVirtualEmail)

    const navigate = useNavigate();

    const nav = [
        { id: 1, name: t("Vank.Transaction.VankPay.SendVankPay.HeaderOfShares.Actions.VankPay"), link: "/send/vankpay", active: true },
        { id: 2, name: t("Vank.Transaction.VankPay.SendVankPay.HeaderOfShares.Actions.Crypto"), link: "/send/crypto", active: false },
        { id: 3, name: t("Vank.Transaction.VankPay.SendVankPay.HeaderOfShares.Actions.Fiat"), link: "/send/fiat", active: false },
    ];

    const handleStroke = () => {
        setColorStroke("#FFF133")
    }

    const closeModalQR = () => {
        setViewModelQR(!viewModelQR)
    }


    useEffect(() => {

        const captureToken = async () => {

            const virtualEmail = await serviceToken.getVirtualEmail();

            const dataT = {
                GENERATEDBY: virtualEmail
            }

            setDataTokent(dataT)
            setGetTokenValueVitualEmail({ GENERATEDBY: virtualEmail })

        }

        captureToken()

    }, [])

    return (
        <div className="">
            <div className="">
                
                <div className="w-full flex-col  items-start justify-center gap-2 inline-flex">

                    <div className="relative w-full -top-4">
                        <div className="absolute right-0 top-0">
                            <UserProfileAndNotifications />
                        </div>
                    </div>

                    <button className="min-w-max min-h-4 justify-start items-center gap-1 flex"
                        onClick={() => navigate(-1)}
                    >
                        <div className="w-2.5 h-4 ">
                            <ArrowHide className="rotate-90 w-full h-full" stroke="#2D2E2F" />{" "}
                        </div>
                        <span className="text-zinc-800 text-base font-normal">{t("Vank.Transaction.VankPay.SendVankPay.back")}</span>
                    </button>

                    <div className="w-full h-12 flex-col justify-start items-start inline-flex">
                        <div className="w-[156px] h-7 justify-start items-center gap-1 inline-flex">
                            <span className="text-[--color-darkest-grey] text-[22px] font-semibold">{title}</span>
                        </div>
                        <span className="text-[#5A5D5A] text-sm font-normal leading-tight">{subtext}</span>
                    </div>

                </div>
                {visibleItem &&
                    <div className="w-1/2  min-w-full h-9 justify-between items-center inline-flex">

                        <div className="max-w-min h-9 bg-[#FFF] rounded-lg justify-start items-center inline-flex">
                            {nav.map(({ active, name, link, id }) => (
                                <button
                                    key={id}
                                    className={`w-[100px] h-[36px] ${active === isActive
                                        ? "flex items-center justify-center gap-4 px-6 rounded-[8px] bg-[--color-darkest-grey]"
                                        : "p-1 flex flex-col items-center gap-4 rounded-[2px] "
                                        } cursor-pointer`}
                                    onClick={() => navigate(link)}
                                >
                                    {active === isActive ? (
                                        <span className="text-[--color-lightest-white] text-base leading-[24px]">
                                            {name}
                                        </span>
                                    ) : (
                                        <div className="w-full h-full flex items-center justify-center px-5 bg-[#FFF] rounded-[5.344px]">
                                            {name}
                                        </div>
                                    )}
                                </button>
                            ))}
                        </div>

                    </div>
                }
            </div>
        </div>
    )
}

export default HeaderPage
