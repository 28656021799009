import axios, { AxiosRequestConfig } from "axios"
import { StorageService } from "../Vank/Page/services/StorageServiceVanKPay"


type RequestAxios = {
    
    url?:string 
    method?:string
    headers?:any
    data?:unknown
    timeout?:number

}


class ApiClient {

    private config:any

    private async headerRequets(){
    
        const headers = {

            Authorization:`Bearer ${await StorageService.get('token').then(resp=>resp)}`,
            Ip: "192.168.1.9",
            // Ip: await axios("https://api.ipify.org/").then(res=>res.data).catch(err => err),
            verify:await StorageService.get('verify') ,
            geolocation:{},
            date:new Date().toISOString()

        }
        
        return headers;
    }

    

    protected async apiClientPostAll(urlService: string, path: string, body: unknown) {
        
        try {
            this.config = {

                url:`${urlService}/${path}`,
                method: 'POST',
                headers: {},
                data: body,
               
            }

            this.config.headers=await this.headerRequets();
            const response=await axios(this.config);
            return response;

        } catch (error) {
            throw error;
        }

    }



    protected async apiClientGet(urlService: string, path: string,query:string) {

        try {

            
            this.config = {

                url:`${urlService}/${path}/`,
                method: 'GET',
                headers: {},
            
            }
           
            const headers = {

                Authorization:`Bearer ${await StorageService.get('token').then(resp=>resp)}`,
                Ip: "192.168.1.9",
                // Ip: await axios("https://api.ipify.org/").then(res=>res.data).catch(err => err),
                verify:await StorageService.get('verify') ,
                geolocation:{}   
            }

            this.config.headers = headers
            const response=await axios(this.config);
            return response;


        } catch (error) {
            throw error;
        }

    }

    
    protected async apiClientPutAll(urlService: string, path: string, body: unknown) {
  
        try {
            this.config = {

                url:`${urlService}/${path}`,
                method: 'PUT',
                headers: {},
                data: body,
               
            }
            
            this.config.headers=await this.headerRequets();
            const response=await axios(this.config);
            return response;

        } catch (error) {
            throw error;
        }

    }

    protected async apiClientMergeAll(urlService: string, path: string, body: unknown) {
        
        try {
            this.config = {

                url:`${urlService}/${path}`,
                method: 'MERGE',
                headers: {},
                data: body,
               
            }

            this.config.headers=await this.headerRequets();
            const response=await axios(this.config);
            return response;

        } catch (error) {
            throw error;
        }

    }
}

export{ApiClient}