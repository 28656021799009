import { useEffect, useState } from "react";
import Toast from "./Toast/Toast";
import { AnimatePresence } from "framer-motion";
import { useToastState } from "@/Context/ToastContext/ToastContext";

const ToastContainer = ({ position }: { position: string }) => {
  const [toasts, setToasts] = useToastState();

 // Lógica para manejar el progreso de los toasts y el cierre automático
 useEffect(() => {
  const intervalId = setInterval(() => {
    setToasts((prevToasts) =>
      prevToasts.map((toast) => {
        if (toast.progress < 100) {
          return {
            ...toast,
            progress: Math.min(toast.progress + 100 / (toast.autoClose / 100), 100),
          };
        }
        return toast;
      })
    );
  }, 100);

  return () => clearInterval(intervalId);
}, []);

  const getPositionClasses = (position) => {
    switch (position) {
      case "top-left":
        return "top-3 left-3";
      case "top-center":
        return "top-3 left-1/2 -translate-x-1/2";
      case "top-right":
        return "top-3 right-3";
      case "bottom-left":
        return "bottom-3 left-3";
      case "bottom-center":
        return "bottom-3 left-1/2 -translate-x-1/2";
      case "bottom-right":
        return "bottom-3 right-3";
      default:
        return undefined; // Default a top-right si no se especifica
    }
  };  

  const groupedNotifications = toasts?.reduce((groups, notification) => {
    const { position } = notification;
    if (!groups[position]) {
      groups[position] = [];
    }
    groups[position].push(notification);
    return groups;
  }, {});

  return (
    <>
      <AnimatePresence>
        {Object.keys(groupedNotifications).map((p) => (
          <div
            key={p}
            className={`fixed max-w-max z-50 flex flex-col gap-3 ${getPositionClasses(
              p
            )}`}
          >
            {groupedNotifications[p].map((toast, index): any => (
              <Toast key={index} toast={toast} />
            ))}
          </div>
        ))}
      </AnimatePresence>
    </>
  );
};

export default ToastContainer;

// switch (toast.action) {
//   case "processes":
//     return (
//       <ProcessToast
//         id={toast.id}
//         key={index}
//         type={toast.type}
//         message={toast.message}
//         position={toast.position}
//         delay={3000}
//         isLoading={true}
//       />
//     );
//   case "default":
//     return (
//       <Toast
//         id={toast.id}
//         key={index}
//         type={toast.type}
//         message={toast.message}
//         position={position}
//       />
//     );
//   default:
//     return null;
// }
