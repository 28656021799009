import React from "react";
import Vank from "@/assets/Vank.png";
import LogoFooter from "@/assets/Icon/LogoFooter.svg";
import AppleLogo from "@/assets/Icon/AppleLogo.svg";
import PlayLogo from "@/assets/Icon/PlayLogo.svg";
import Facebook from "@/assets/Icon/Facebook.svg";
import SocialX from "@/assets/Icon/SocialX.svg";
import Instagram from "@/assets/Icon/Instagram.svg";
import Youtube from "@/assets/Icon/Youtube.svg";
import { useTranslation } from "react-i18next";
import HighlightWords from "@/apps/Shared/HighlightWords/HighlightWords";

const Footer = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="w-full min-h-[530px] bg-[var(--gray-50)] py-[60px] xl:py-[70px] overflow-hidden">
      <div className="max-w-[1080px] 2xl:max-w-[1200px] mx-auto flex flex-col gap-2">
        <div className="w-full flex justify-center gap-x-28 mb-7 xl:mb-12 2xl:mb-4 xl:px-0 px-5">
          <div className="w-[153px] h-[52px]">
            <img
              src={LogoFooter}
              alt=""
              className="w-full h-full object-contain"
            />
          </div>
          <div className="w-full grid grid-cols-3 xl:grid-cols-4 gap-3">
            <div className="w-full h-full flex flex-col gap-[24px] ">
              <h6 className="font-bold pt-2">
                {t("Landing.footer.products.title")}
              </h6>
              <ul className="flex flex-col gap-3">
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.products.item1")}
                </li>
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.products.item2")}
                </li>
                {/* <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  Multi-currency account
                </li> */}
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.products.item3")}
                </li>
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.products.item4")}
                </li>
              </ul>
            </div>
            <div className="w-full h-full flex flex-col gap-[24px] ">
              <h6 className="font-bold pt-2">
                {t("Landing.footer.company.title")}
              </h6>
              <ul className="flex flex-col gap-3">
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.company.item1")}
                </li>
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.company.item2")}
                </li>
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.company.item3")}
                </li>
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.company.item4")}
                </li>
              </ul>
            </div>
            <div className="w-full h-full flex flex-col gap-[24px] mb-3 xl:mb-0">
              <h6 className="font-bold pt-2">
                {t("Landing.footer.resources.title")}
              </h6>
              <ul className="flex flex-col gap-3">
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.resources.item1")}
                </li>
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.resources.item2")}
                </li>
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.resources.item3")}
                </li>
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.resources.item4")}
                </li>
                <li className="py-1 text-[var(--Gray-600)] cursor-pointer">
                  {t("Landing.footer.resources.item5")}
                </li>
              </ul>
            </div>
            <div className="w-full h-full flex flex-row xl:col-span-1 col-span-3  xl:flex-col gap-3 xl:gap-0">
              <h6 className="w-[150px] xl:max-w-max font-bold pt-2 text-[#727372] xl:mb-[24px]">
                {HighlightWords(t("Landing.footer.download.text"), [
                  t("Landing.footer.download.boldText").toLowerCase(),
                ], "text-[var(--bg-dark)]")}
              </h6>
              <div className="max-w-full flex items-center justify-evenly py-3 px-4 rounded-[40px] bg-[var(--bg-dark)] text-[var(--gray-50)] xl:mb-[20px] cursor-pointer">
                <img src={AppleLogo} alt="" className="" />
                <div className="flex flex-col items-center gap-[3px] text-[var(--Lime-300)]">
                  <p className="text-[12px] leading-none">
                    {t("Landing.footer.appStore.title")}
                  </p>
                  <p className="text-lg xl:text-xl leading-none">
                    {t("Landing.footer.appStore.download")}
                  </p>
                </div>
              </div>
              <div className="max-w-full flex items-center justify-evenly py-3 px-4 rounded-[40px] bg-[var(--bg-dark)] text-[var(--gray-50)] cursor-pointer">
                <img src={PlayLogo} alt="" />
                <div className="flex flex-col items-center gap-[3px] text-[var(--Lime-300)]">
                  <p className="text-[12px] leading-none">
                    {t("Landing.footer.googlePlay.title")}
                  </p>
                  <p className="text-lg xl:text-xl leading-none">
                    {t("Landing.footer.googlePlay.download")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-5 xl:mb-12 2xl:mb-4" />
        <div className="w-full flex items-center justify-between px-12 xl:pl-2">
          <h3 className="mr-12">{t("Landing.footer.copyright")}</h3>
          <div className="flex items-center gap-2">
            <div className="group relative">
              <button className="w-[48px] h-[48px]">
                <img
                  src={Facebook}
                  className="w-full h-full object-contain"
                  alt=""
                />
              </button>
              <span
                className="absolute -top-12 left-[50%] -translate-x-[50%] 
                          z-20 origin-left scale-0 px-3 rounded-lg border 
                          border-gray-300 bg-white py-2 text-sm font-bold
                          shadow-md transition-all duration-300 ease-in-out 
                          group-hover:scale-100"
              >
                Facebook
              </span>
            </div>
            <div className="group relative">
              <button className="w-[48px] h-[48px]">
                <img
                  src={SocialX}
                  className="w-full h-full object-contain"
                  alt=""
                />
              </button>
              <span
                className="absolute -top-12 left-[50%] -translate-x-[50%] 
                          z-20 origin-left scale-0 px-3 rounded-lg border 
                          border-gray-300 bg-white py-2 text-sm font-bold
                          shadow-md transition-all duration-300 ease-in-out 
                          group-hover:scale-100"
              >
                SocialX
              </span>
            </div>
            <div className="group relative">
              <button className="w-[48px] h-[48px]">
                <img
                  src={Instagram}
                  className="w-full h-full object-contain"
                  alt=""
                />
              </button>
              <span
                className="absolute -top-12 left-[50%] -translate-x-[50%] 
                          z-20 origin-left scale-0 px-3 rounded-lg border 
                          border-gray-300 bg-white py-2 text-sm font-bold
                          shadow-md transition-all duration-300 ease-in-out 
                          group-hover:scale-100"
              >
                Instagram
              </span>
            </div>
            <div className="group relative">
              <button className="w-[48px] h-[48px]">
                <img
                  src={Youtube}
                  className="w-full h-full object-contain"
                  alt=""
                />
              </button>
              <span
                className="absolute -top-12 left-[50%] -translate-x-[50%] 
                          z-20 origin-left scale-0 px-3 rounded-lg border 
                          border-gray-300 bg-white py-2 text-sm font-bold
                          shadow-md transition-all duration-300 ease-in-out 
                          group-hover:scale-100"
              >
                Youtube
              </span>
            </div>
          </div>
          <div />
        </div>
      </div>
    </div>
  );
};

export default Footer;
