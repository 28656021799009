import React from "react";

export const IconNotification = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9935 16.7153C11.9937 17.1867 11.803 17.6407 11.4596 17.9863C11.1163 18.3319 10.6457 18.5436 10.1422 18.579L9.9921 18.5837H7.99071C7.48579 18.5838 6.99946 18.4058 6.62922 18.0853C6.25898 17.7648 6.0322 17.3255 5.99433 16.8554L5.98932 16.7153H11.9935ZM8.99141 0.834229C10.8076 0.834201 12.5529 1.49273 13.859 2.6709C15.1651 3.84907 15.93 5.45477 15.9923 7.14929L15.9963 7.3735V10.8898L17.8195 14.2939C17.8991 14.4424 17.9389 14.6068 17.9356 14.7728C17.9323 14.9389 17.8859 15.1017 17.8004 15.2473C17.7149 15.393 17.5929 15.5171 17.4448 15.6091C17.2966 15.7011 17.1269 15.7582 16.9499 15.7755L16.8349 15.7811H1.14796C0.970005 15.7812 0.794686 15.7409 0.637031 15.6639C0.479375 15.5868 0.344085 15.4752 0.242753 15.3387C0.141421 15.2021 0.0770684 15.0446 0.0552102 14.8797C0.0333519 14.7149 0.0546396 14.5475 0.117249 14.392L0.163281 14.2939L1.98655 10.8898V7.3735C1.98655 5.63917 2.72456 3.97589 4.03822 2.74954C5.35189 1.52319 7.1336 0.834229 8.99141 0.834229Z"
        fill="#D4D4D5"
      />
    </svg>
  );
};
