import CustomButton from '@/apps/Shared/CustomButton/CustomButton'
import HighlightWords from '@/apps/Shared/HighlightWords/HighlightWords';
import { HeaderModal } from '@/apps/Vank/Page/Home/Modales/HeaderModal/HeaderModal'
import { CustomButtonsForms } from '@/apps/Vank/Page/shared/CustomButtonsForms/CustomButtonsForms'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';


const ModalchangingSections = ({ onClose, link, type }: { onClose: any; link?: any; type?: any }) => {
    const [t] = useTranslation("global");
    const navigate = useNavigate();

    return (
        <div className='w-[460px] min-h-[191px] bg-[var(--bacground-component-module-light)] flex py-[16px] flex-col items-start gap-[16px] rounded-[16px]'>
            <div className='w-full h-[20px] flex justify-between items-center px-[16px]'>
                <span className="text-center text-[var(--content-main-black-light)] text-xl font-medium">
                    {type === 'send' ? t("Vank.Transaction.Money.SendMoney.HeaderOfShares.Recharge.Modal.text") : t("Vank.Transaction.Money.ReceiveMoney.HeaderOfShares.Recharge.Modal.text")}
                </span>
                <button
                    className="w-8 h-8 rounded-[500px] justify-center items-center gap-2.5 flex hover:bg-[var(--background-tab-selected)] transition-all duration-300"
                    onClick={() => onClose(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        className="w-[18px] h-[18px]"
                    >
                        <path
                            d="M5 5.5L13 13.5"
                            stroke="var(--content-main-gray-light)"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M13 5.5L5 13.5"
                            stroke="var(--content-main-gray-light)"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
            <div className='min-h-[1px] bg-[var(--strokes-separator-separator-light)] min-w-[460px]'></div>
            <div className='min-w-[460px] min-h-[106px] flex flex-col items-start gap-[32px] '>
                <div className='min-w-[460px] min-h-[34px] flex px-[16px] justify-center items-center gap-[10px] '>
                    {type === 'send' ? (
                        <p className='text-[var(--content-main-gray-light)] font-Inter font-[14px] leading-normal '>{
                            HighlightWords(t("Vank.Transaction.Money.SendMoney.HeaderOfShares.Recharge.Modal.descripcion.text"), [
                                t(
                                  "Vank.Transaction.Money.SendMoney.HeaderOfShares.Recharge.Modal.descripcion.textBoldOne"
                                ).toLowerCase(),
                                t(
                                  "Vank.Transaction.Money.SendMoney.HeaderOfShares.Recharge.Modal.descripcion.textBoldTwo"
                                ).toLowerCase(),
                              ], "text-[var(--content-main-black-light)] font-medium")
                        }</p>
                    ) : (
                        <p className='text-[var(--content-main-gray-light)] font-Inter font-[14px] leading-normal '>
                            { HighlightWords(t("Vank.Transaction.Money.ReceiveMoney.HeaderOfShares.Recharge.Modal.descripcion.text"), [
                                t(
                                  "Vank.Transaction.Money.ReceiveMoney.HeaderOfShares.Recharge.Modal.descripcion.textBoldOne"
                                ).toLowerCase(),
                                t(
                                  "Vank.Transaction.Money.ReceiveMoney.HeaderOfShares.Recharge.Modal.descripcion.textBoldTwo"
                                ).toLowerCase(),
                              ], "text-[var(--content-main-black-light)] font-medium")}
                        </p>
                    )}
                </div>
                <div className='min-w-[460px] min-h-[40px] flex px-[16px] items-start gap-[16px] '>
                    <CustomButtonsForms onClickClear={() => onClose(false)} label={t("Vank.Transaction.Money.SendMoney.HeaderOfShares.Recharge.Modal.continue")} labelClear={t("Vank.Transaction.Money.SendMoney.HeaderOfShares.Recharge.Modal.cancel")} onClickContinue={() => navigate(link)} />
                </div>
            </div>
        </div>
    )
}

export default ModalchangingSections
