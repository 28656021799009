import { AxiosClientRepository } from "@/apps/Shared/infrastructura/Http/AxiosHttp";
import { environment } from "@/environments/environment.dev";
import { ServiceDecodiToken } from "./ServiceDecodiToken";

export class ServicesPrints extends AxiosClientRepository{

    
    
    public async getPrints(data: any) {
      const serviceToken=new ServiceDecodiToken();

      const emisor=await serviceToken.getEmail();
      
      console.log(data,"data service prints", emisor);
      
      try {
        const url = `${environment.url_servers.url_prints}/prints/postPrintsVankPay?query=`+JSON.stringify({CODERECEIPT:data.CODERECEIPT,ACTION:data.ACTION,GENERATEDBY:data.GENERATEDBY,URL:data.URL,TYPESEND:data.TYPESEND,TO_EMAIL:data.TO_EMAIL,EMISOR:emisor});
        let response = await this.get({ url });
        return response;
      } catch (error) {
        throw error;
      }
    }


    public async shareLinkFeature(data:any){
      try {
        const url = `${environment.url_servers.url_orchertractor}/pdfUrl`;
        let response = await this.post({ url ,data});
        return response;
      } catch (error) {
        throw error;
      }
    }
  }

