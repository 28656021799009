import VankLogo from "@/assets/Icon/VankLogo";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  IconHome,
  IconSettings,
  IconUsers,
  IconHelpCenter,
  IconArrowReceive,
  IconArrowSend,
  IconTransferConvert,
  IconCard,
  IconMenu,
} from "@/assets/IconV2/IconsSvg";
import { IconLogout } from "@/assets/Icon/IconLogout";
import { Tooltip } from "react-tooltip";
import "./Sidebar.css";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import { counterSelector, ProfileUser } from "@/apps/Vank/Page/Home/state";
import { useRecoilState } from "recoil";
import Onboarding from "@/apps/Vank/Page/Home/Onboarding/Onboarding";
import { IndexedDBLocalDropdownRepository } from "../../infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import HighlightWords from "../../HighlightWords/HighlightWords";
import { ServicesHome } from "@/apps/Vank/Page/Home/Services/ServicesHome";
import ButtonExpand from "../components/ButtonExpand/ButtonExpand";
import CustomModal from "../../CustomModal/CustomModal";

export const Sidebar = ({
  setIsOpenSend,
  setIsOpenReload,
  toggleOpen,
}: {
  setIsOpenSend?: any;
  setIsOpenReload?: any;
  toggleOpen?: any;
  externalExpanded?: boolean;
  setExternalExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const location = useLocation();

  const navigate = useNavigate();

  const services = new ServicesHome();

  const [t, i18n] = useTranslation("global");
  const [c, setC] = useState<string>("");
  const [active, setActive] = useState<number>(0);
  const [expanded, setExpanded] = useState(true);
  const [counter, setCounter] = useRecoilState(counterSelector);
  const [users, setUsers] = useRecoilState<any>(ProfileUser);

  //mobile
  const [isExpanded, setIsExpanded] = useState(() => {
    const storedState = localStorage.getItem('isSidebarExpanded');
    return storedState === 'true';
  });

  const listLinksHeader = [
    {
      id: 1,
      path: "/Home",
      icon: <IconHome />,
      text: t("Vank.Share.Sidebar.Link.Home"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      active: true,
    },
    {
      id: 2,
      path: "/send",
      icon: <IconArrowSend />,
      text: t("Vank.Share.Sidebar.Link.Send"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      active: true,
    },
    {
      id: 3,
      path: "/receive",
      icon: <IconArrowReceive />,
      text: t("Vank.Share.Sidebar.Link.Recharge"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      active: true,
    },
    {
      id: 4,
      path: "/convert",
      icon: <IconTransferConvert />,
      text: t("Vank.Share.Sidebar.Link.Convert"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      active: true,
    },
    {
      id: 5,
      path: "/recipient",
      icon: <IconUsers />,
      text: t("Destinatario"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      active: true,
    },
    {
      id: 6,
      path: "/Home",
      icon: <IconCard />,
      text: t("Vank.Share.Sidebar.Link.Cards"),
      className: `${"text-[18px] max-2xl:text-[15px]"}`,
      active: true, 
    },
    // {
    //   id: 7,
    //   path: "",
    //   icon: <IconMenu />,
    //   text: t("Menu"),
    //   className: `${"text-[18px] max-2xl:text-[15px]"}`,
    //   active: true,
    // },
  ];

  const listLinksFooter = [
    // {
    //   id: 1,
    //   path: "/Home",
    //   icon: <News />,
    //   className: "",
    //   text: t("Vank.Share.Sidebar.Link.News"),
    //   link: `${location.pathname}`,
    // },
    {
      id: 2,
      path: "/Home",
      icon: <IconHelpCenter />,
      className: "",
      text: t("Vank.Share.Sidebar.Link.Help"),
      link: `${location.pathname}`,
    },
    {
      id: 3,
      path: "/Home",
      icon: <IconSettings />,
      className: "",
      text: t("Vank.Share.Sidebar.Link.Settings"),
      link: `${location.pathname}`,
    },
    {
      id: 4,
      path: "/Home",
      icon: <IconLogout />,
      className: "",
      text: t("Vank.Share.Sidebar.Link.BtnLogOut"),
      link: "/",
    },
  ];

  useEffect(() => {
    //   i18n.changeLanguage('es')
    const matchedLinks = listLinksHeader.find((link) =>
      location.pathname.includes(link.path)
    );
    // const matchedIds = matchedLinks.map(link => link.id);
    setActive(matchedLinks?.id);
  }, [location.pathname]);

  // only tests
  const updateOnboarding = async () => {
    try {
      const res = await services.updateOnboarding({ STATUSINCODE: 2 });
      console.log(res);
      let _user = {
        ...users,
        STATUSINCODE: 2,
      };
      await new IndexedDBLocalDropdownRepository().save({
        key: "user",
        data: _user,
      });
      setUsers((user: any) => ({
        ...user,
        ..._user,
      }));
      const _counter = _user?.STATUSINCODE === 2 ? 1 : 0;
      setCounter(_counter);
    } catch (error) {
      console.log(error);
      console.log("hay problemas en la actualizacion del onboarding");
    }
  };

  //recibe - muestra el valor que envia desde el home para expandir o no
  useEffect(() => {
    const handleStorageChange = () => {
      const isHomeExpanded = localStorage.getItem('isHomeExpanded') === 'true';
      const isSidebarExpanded = localStorage.getItem('isSidebarExpanded') === 'true';

      if (isHomeExpanded) {
        setIsExpanded(true);
      } else if (!isSidebarExpanded) {
        setIsExpanded(false);
      }
    };

    const handleResize = () => {

    };

    window.addEventListener('storage', handleStorageChange);
    handleStorageChange(); // Llamar inicialmente para actualizar el estado

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  //efecto para ocultar la modal mobile
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 773) {
        setIsExpanded(false);
      }
    };
    // Verifica el tamaño de la ventana en el primer renderizado
    handleResize();
    // Escucha cambios en el tamaño de la ventana
    window.addEventListener('resize', handleResize);
    // Limpia el event listener cuando el componente se desmonte
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const toggleExpandSidebar = () => {
    setIsExpanded(!isExpanded);
    localStorage.setItem('isSidebarExpanded', (!isExpanded).toString());
    localStorage.setItem('isHomeExpanded', 'false');
    window.dispatchEvent(new Event('storage')); // Dispara evento para sincronizar otros componentes
  };

  const OpenMenuMobile = () => {
    console.log("entro a abrirlo");
    
    setIsExpanded((prev) => !prev);
  }

  //--------------------------------------------------------------------------




  return (
    <div>
      <div className={` Slider  h-full 2xl:block xl:block lg:block md:block mobile:hidden 2xl:min-h-screen xl:min-h-screen lg:min-h-screen md:min-h-screen sm:min-h-screen ${expanded ? "w-[236px]" : "w-[108px]"
        } flex flex-col justify-between   px-[24px] left-0 top-0 sticky z-20  bg-[var(--bacground-sidebar-light)] shadow  items-start transition-all duration-500`}
      >
        <div className="flex flex-col  min-h-screen py-[36px] justify-between">
          <div className="w-full  h-full flex-col justify-start items-center gap-4 flex">
            <div className="w-full  flex-col justify-center items-center gap-2 inline-flex overflow-hidden">
              {expanded ? (
                <VankLogo fill="var(--content-main-black-light)" className="h-[37px]" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="52"
                  height="37"
                  viewBox="0 0 52 37"
                  fill="none"
                >
                  <path
                    d="M32.0062 36.6943C32.0029 36.4253 31.99 36.1597 31.99 35.8897C31.99 29.3308 33.0743 22.9101 35.2429 16.6288C37.4126 10.3475 40.3074 5.13751 43.9293 1H35.1577C31.7256 4.63315 28.9625 9.62819 26.864 15.9851C24.7677 22.342 23.7179 28.9776 23.7179 35.8897C23.7179 36.1597 23.7297 36.4253 23.733 36.6943H32.0051H32.0062ZM15.7932 36.6943H24.3048L15.5116 2.26469H7L15.7932 36.6943Z"
                    fill="var(--content-main-black-light)"
                  />
                </svg>
              )}
            </div>

            {/* HEADER: are the actions of the slider (Home, Send, Reload, Convert, Favorites, Accounts). */}
            <div
              className={`w-full h-[70%]   flex-col justify-start items-start ${counter === 1 || counter === 3 ? "gap-0" : "gap-[1px]"
                } inline-flex `}
              style={{
                pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
              }}
            >
              {listLinksHeader.map((item, index) => (
                <button
                  key={index}
                  className={`relative w-full  p-4 ${active === item.id && (counter === 0 || counter > 12)
                    ? "shadow bg-[var(--bacground-component-module-light)] rounded-xl"
                    : "shadow-none"
                    }
                        ${item.id === 4
                      ? "shadow  bg-[var(--bacground-component-module-light)] hidden rounded-xl"
                      : "shadow-none"
                    } 
                    ${counter === 1 && item.id === 1 && "bg-[var(--bacground-component-module-light)] z-50 rounded-xl"}
                    ${counter === 3 &&
                    (item.id === 2 || item.id === 3 || item.id === 4) &&
                    "bg-[var(--bacground-component-module-light)] z-50"
                    }
                    ${counter === 3 && item.id === 2 && "rounded-t-xl"}
                    ${counter === 3 && item.id === 3 && "rounded-none"}
                    ${counter === 3 && item.id === 4 && "rounded-b-xl"}
                    ${item.id === 7 && "shadow border-t border-t-[#FFF133]   hidden" && "shadow-none hidden"
                    }
                    
                    justify-start items-center gap-4 inline-flex transition-all duration-300 cursor-pointer`}
                  onClick={() => {
                    if (item.text === t("Vank.Share.Sidebar.Link.Send")) {
                      setIsOpenSend(true);
                      return;
                    } else if (
                      item.text === t("Vank.Share.Sidebar.Link.Recharge")
                    ) {
                      setIsOpenReload(true);
                      return;
                    } else if (index === listLinksHeader?.length - 1) return;
                    navigate(item.path);
                  }}
                  disabled={users?.STATUSINCODE === 2}
                >
                  <span className="w-7 h-7 flex justify-center items-center">
                    {item.icon}
                  </span>
                  <div
                    className={`${expanded ? "w-[110px]" : "w-0"
                      } justify-start items-center gap-2.5 flex transition-all duration-500 overflow-hidden`}
                  >
                    <div
                      className={`text-center text-lg ${active === item.id ? "font-medium" : "font-normal"
                        } ${!item?.active ? "text-[#C0C0C1]" : "var(--content-main-black-light)"}`}
                      id={!item?.active && "my-anchor-element-id"}
                    >
                      {item.text}
                    </div>
                    <Tooltip
                      anchorSelect="#my-anchor-element-id"
                      content="Disponible muy pronto!"
                      place="right"
                      variant="light"
                      style={{
                        boxShadow: "-2px 1px 10px 0px rgba(0, 0, 0, 0.07)",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                  {counter === 1 && item.id === 1 && (
                    <div className="absolute min-w-[338px] min-h-[98px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-start items-center bg-[var(--bacground-component-module-light)] py-4 pl-10 pr-4 z-50">
                      <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                        <span className="text-[14px] text-[#000] font-normal">
                          {counter}
                        </span>
                      </div>
                      <p className="text-[18px] text-left text-[var(--content-main-black-light)] leading-[21.78px] font-normal">
                        {HighlightWords(t("Vank.Home.Onboarding.One.text"), [
                          t("Vank.Home.Onboarding.One.boldText").toLowerCase(),
                        ])}
                      </p>
                    </div>
                  )}
                  {index === 0 && counter === 3 && (
                    <div className="absolute min-w-[446px] min-h-[76px] rounded-[16px] top-16 left-[110%] flex justify-center items-center bg-[var(--bacground-component-module-light)] py-4 pl-8 pr-4 z-50">
                      <div className="absolute -left-2 top-1/2 -translate-y-1/2 min-w-[24px] min-h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                        <span className="text-[14px] text-[#000] font-normal">
                          {counter}
                        </span>
                      </div>
                      <p className="w-[398px] text-[18px] text-left text-[var(--content-main-black-light)] leading-[21.78px] font-normal">
                        {t("Vank.Home.Onboarding.Three")}
                      </p>
                    </div>
                  )}
                </button>
              ))}
            </div>
          </div>

          <div className="w-full  h-full  flex-col justify-end items-center gap-4 flex">
            {/* FOOTER: are the slider actions (Help, Settings, Exit). */}
            <div
              className="w-full  flex-col justify-center items-start inline-flex "
              style={{
                pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
              }}
            >
              {listLinksFooter.map((item, index) => (
                <button
                  className={`relative   w-full p-4 rounded-xl justify-start items-center gap-4 inline-flex
                      ${counter === 9 && item.id === 2 && "bg-[var(--bacground-component-module-light)] z-50"}
                      ${counter === 10 && item.id === 3 && "bg-[var(--bacground-component-module-light)] z-50"}
                      transition-all duration-300`}
                  key={index}
                  onClick={() => {
                    if (item.text === t("Vank.Share.Sidebar.Link.BtnLogOut")) {
                      StorageService.delete("token");
                      StorageService.delete("verify");
                      navigate("/");
                      return;
                    }
                    else if (
                      item.text === t("Vank.Share.Sidebar.Link.Settings")
                    ) {
                      updateOnboarding();
                    }
                  }}
                  disabled={users?.STATUSINCODE === 2}
                >
                  <span className="w-7 h-7">{item.icon}</span>
                  <div
                    className={`${expanded ? "w-[110px]" : "w-0"
                      }  justify-start items-center gap-2.5  transition-all duration-500 flex overflow-hidden `}
                  >
                    <div className="text-center text-[var(--content-main-black-light)] text-lg font-normal">
                      {item.text}
                    </div>
                  </div>
                  {counter === 9 && item.id === 2 && (
                    <div className="absolute w-[500px] h-[76px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-start items-center bg-[var(--bacground-component-module-light)] py-4 pl-10 pr-4 z-50">
                      <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                        <span className="text-[14px] text-[#000000] font-normal">
                          {counter}
                        </span>
                      </div>
                      <p className="text-[18px] text-left text-[var(--content-main-black-light)] font-normal leading-[21.78px]">
                        {t("Vank.Home.Onboarding.Nine")}
                      </p>
                    </div>
                  )}
                  {counter === 10 && item.id === 3 && (
                    <div className="absolute  w-[443px] min-h-[76px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-center items-start bg-[var(--bacground-component-module-light)] py-4 pl-10 pr-4 z-50">
                      <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                        <span className="text-[14px] text-[#000] font-normal">
                          {counter}
                        </span>
                      </div>
                      <p className="text-[18px] text-left text-[#000] font-normal leading-[21.78px]">
                        {t("Vank.Home.Onboarding.Ten")}
                      </p>
                    </div>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
        <button
          className="absolute  bg-[#fff] z-20 top-[52px] -right-3 w-[28px] h-[28px] flex justify-center items-center bg-[var(--bacground-sidebar-light)] rounded-[14px]"
          onClick={() => setExpanded((curr) => !curr)}
        >
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H10.3333"
              stroke="var(--content-main-black-light)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 5H6.33333"
              stroke="var(--content-main-black-light)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 9H10.3333"
              stroke="var(--content-main-black-light)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <Onboarding />
      </div>

      {/* mobile */}
      <div className={`${isExpanded ? 'mobile' : ''}`}>
        <CustomModal isOpen={isExpanded}  >
          <div onClick={toggleExpandSidebar}
            className={`fixed h-full top-0 left-0 z-50  mobile:block 2xl:hidden mobile:min-h-screen bg-opacity-50 transition-opacity duration-2000 transform ${isExpanded ? "w-[236px] opacity-100 translate-x-0" : "w-0 opacity-0 pointer-events-none -translate-x-full"
              } flex justify-between  bg-[var(--bacground-sidebar-light)] shadow z-30 items-start`}
          >
            <div className=" h-full flex items-start flex-col gap-16 px-2 pt-[6rem]  overflow-scroll">
              <div className="w-full   flex-col justify-start items-center gap-4 inline-flex">
                {/* HEADER: are the actions of the slider (Home, Send, Reload, Convert, Favorites, Accounts). */}
                <div
                  className={` relative  left-0 w-full  flex-col justify-start items-start ${counter === 1 || counter === 3 ? "gap-[0px]" : "gap-[1px]"
                    } inline-flex  `}
                  style={{
                    pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
                  }}
                >
                  {listLinksHeader.map((item, index) => (
                    <button
                      key={index}
                      className={`${isExpanded ? " w-full opacity-100" : " w-0 opacity-0 pointer-events-none z-0"} w-full relative  p-4 ${active === item.id && (counter === 0 || counter > 12)
                        ? "shadow bg-[var(--bacground-component-module-light)] rounded-xl"
                        : "shadow-none "

                        } 
                    ${counter === 1 && item.id === 1 && "bg-[var(--bacground-component-module-light)] z-50 rounded-xl"}
                    ${counter === 3 &&
                        (item.id === 2 || item.id === 3 || item.id === 4) &&
                        "bg-[var(--bacground-component-module-light)] z-50"
                        }
                    ${counter === 3 && item.id === 2 && "rounded-t-xl"}
                    ${counter === 3 && item.id === 3 && "rounded-none"}
                    ${counter === 3 && item.id === 4 && "rounded-b-xl"}

                    ${item.id === 7 && "shadow border-t border-t-[#FFF133]   hidden" && "hidden "
                        }
                    justify-start items-center gap-4 inline-flex transition-all duration-300 cursor-pointer`}
                      onClick={() => {
                        if (item.text === t("Vank.Share.Sidebar.Link.Send")) {
                          setIsOpenSend(true);
                          return;
                        } else if (
                          item.text === t("Vank.Share.Sidebar.Link.Recharge")
                        ) {
                          setIsOpenReload(true);
                          return;
                        } else if (index === listLinksHeader?.length - 1) return;
                        navigate(item.path);
                      }}
                      disabled={users?.STATUSINCODE === 2}
                    >
                      <span className="w-7 h-7 flex justify-center items-center">
                        {item.icon}
                      </span>
                      <div
                        className={`${isExpanded ? "w-[110px]" : "w-0 "
                          } justify-start items-center gap-2.5 flex transition-all duration-500 overflow-hidden`}
                      >
                        <div
                          className={`text-center text-lg ${active === item.id ? "font-medium" : "font-normal"
                            } ${!item?.active ? "text-[#C0C0C1]" : "var(--content-main-black-light)"}`}
                          id={!item?.active && "my-anchor-element-id"}
                        >
                          {item.text}
                        </div>
                        <Tooltip
                          anchorSelect="#my-anchor-element-id"
                          content="Disponible muy pronto!"
                          place="right"
                          variant="light"
                          style={{
                            boxShadow: "-2px 1px 10px 0px rgba(0, 0, 0, 0.07)",
                            borderRadius: "8px",
                          }}
                        />
                      </div>
                      {counter === 1 && item.id === 1 && (
                        <div className="absolute  min-w-[338px] min-h-[98px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-start items-center bg-[var(--bacground-component-module-light)] py-4 pl-10 pr-4 z-50">
                          <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                            <span className="text-[14px] text-[#000] font-normal">
                              {counter}
                            </span>
                          </div>
                          <p className="text-[18px] text-left text-[var(--content-main-black-light)] leading-[21.78px] font-normal">
                            {HighlightWords(t("Vank.Home.Onboarding.One.text"), [
                              t("Vank.Home.Onboarding.One.boldText").toLowerCase(),
                            ])}
                          </p>
                        </div>
                      )}
                      {index === 0 && counter === 3 && (
                        <div className="absolute min-w-[446px] min-h-[76px] rounded-[16px] top-16 left-[110%] flex justify-center items-center bg-[var(--bacground-component-module-light)] py-4 pl-8 pr-4 z-50">
                          <div className="absolute -left-2 top-1/2 -translate-y-1/2 min-w-[24px] min-h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                            <span className="text-[14px] text-[#000] font-normal">
                              {counter}
                            </span>
                          </div>
                          <p className="w-[398px] text-[18px] text-left text-[var(--content-main-black-light)] leading-[21.78px] font-normal">
                            {t("Vank.Home.Onboarding.Three")}
                          </p>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              </div>

              <div className="w-full    flex-col justify-end items-center gap-4 inline-flex">
                {/* FOOTER: are the slider actions (Help, Settings, Exit). */}
                <div
                  className="w-full h-60 flex-col justify-center items-start inline-flex "
                  style={{
                    pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
                  }}
                >
                  {listLinksFooter.map((item, index) => (
                    <button
                      className={`${isExpanded ? " w-full opacity-100" : " w-0 opacity-0 pointer-events-none z-0"}  w-full min-h-[60px] p-4 rounded-xl justify-start items-center gap-4 inline-flex
                      ${counter === 9 && item.id === 2 && "bg-[var(--bacground-component-module-light)] z-50"}
                      ${counter === 10 && item.id === 3 && "bg-[var(--bacground-component-module-light)] z-50"}
                      transition-all duration-300`}
                      key={index}
                      onClick={() => {
                        if (item.text === t("Vank.Share.Sidebar.Link.BtnLogOut")) {
                          StorageService.delete("token");
                          StorageService.delete("verify");
                          navigate("/");
                          return;
                        }
                        else if (
                          item.text === t("Vank.Share.Sidebar.Link.Settings")
                        ) {
                          updateOnboarding();
                        }
                      }}
                      disabled={users?.STATUSINCODE === 2}
                    >
                      <span className="w-7 h-7">{item.icon}</span>
                      <div
                        className={`${isExpanded ? "w-[110px]" : "w-0"
                          }  justify-start items-center gap-2.5 transition-all duration-500 flex overflow-hidden `}
                      >
                        <div className="text-center text-[var(--content-main-black-light)] text-lg font-normal">
                          {item.text}
                        </div>
                      </div>
                      {counter === 9 && item.id === 2 && (
                        <div className="absolute w-[500px] h-[76px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-start items-center bg-[var(--bacground-component-module-light)] py-4 pl-10 pr-4 z-50">
                          <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                            <span className="text-[14px] text-[#000000] font-normal">
                              {counter}
                            </span>
                          </div>
                          <p className="text-[18px] text-left text-[var(--content-main-black-light)] font-normal leading-[21.78px]">
                            {t("Vank.Home.Onboarding.Nine")}
                          </p>
                        </div>
                      )}
                      {counter === 10 && item.id === 3 && (
                        <div className="absolute w-[443px] min-h-[76px] rounded-[16px] top-1/2 -translate-y-1/2 left-[110%] flex justify-center items-start bg-[var(--bacground-component-module-light)] py-4 pl-10 pr-4 z-50">
                          <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                            <span className="text-[14px] text-[#000] font-normal">
                              {counter}
                            </span>
                          </div>
                          <p className="text-[18px] text-left text-[#000] font-normal leading-[21.78px]">
                            {t("Vank.Home.Onboarding.Ten")}
                          </p>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <ButtonExpand setExpandebutton={toggleExpandSidebar} svgElementClose className={`${isExpanded ? "block" : "hidden"} absolute p-2 bg-[#fff] rounded-full top-[52px] -right-3 w-[28px] h-[28px] flex justify-center items-center bg-[var(--bacground-sidebar-light)]`} />

          </div>
        </CustomModal>
      </div> 

      <div className="fixed bg-[#fff] z-40 pt-2  bottom-0 w-full  2xl:hidden xl:hidden lg:hidden md:hidden sm:flex mobile:flex  justify-between  items-start ">
        <div className={` bg-[var(--bacground-sidebar-light)] shadow z-50  w-full`}>
          <div className="w-full justify-start items-center ">

            {/* HEADER: are the actions of the slider (Home, Send, Reload, Convert, Favorites, Accounts). */}
            <div
              className={`w-full  justify-start items-start inline-flex ${counter === 1 && "z-50"} `} style={{ pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto", }}>

              {listLinksHeader.map((item, index) => (
                <>
                  <button
                    key={index}
                    className={`relative  w-full flex flex-col min-h-[59px] py-[8px] ${active === item.id && item.id === 1 || active === item.id && item.id === 4
                      ? "shadow border-t border-t-[#FFF133]    "
                      : "shadow-none "
                      } 
                          ${active === item.id && item.id
                        ? "shadow border-t border-t-[#FFF133]  "
                        : "shadow-none "
                      } 
                          ${item.id === 5 || item.id === 6
                        ? "shadow border-t border-t-[#FFF133]   hidden"
                        : "shadow-none "
                      }
                        ${item.id === 7
                        ? "shadow border-t border-t-[#FFF133]  h-full"
                        : "shadow-none "
                      }
                          ${counter === 1 && item.id === 1 && "bg-[var(--bacground-component-module-light)] z-50"}
                          ${counter === 3 && item.id === 2 && "bg-[var(--bacground-component-module-light)] z-50"}
                          ${counter === 3 && item.id === 3 && "bg-[var(--bacground-component-module-light)] z-50"}
                          ${counter === 3 && item.id === 4 && "bg-[var(--bacground-component-module-light)] z-50"}

                      justify-start items-center  inline-flex transition-all duration-300 cursor-pointer`}

                    onClick={() => {
                      if (item.id === 7) {
                        OpenMenuMobile();
                      }
                      if (item.text === t("Vank.Share.Sidebar.Link.Send")) {
                        setIsOpenSend(true);
                        return;
                      } else if (
                        item.text === t("Vank.Share.Sidebar.Link.Recharge")
                      ) {
                        setIsOpenReload(true);
                        return;
                      } else if (index === listLinksHeader?.length - 1) return;
                      navigate(item.path);

                      
                    }}
                    disabled={users?.STATUSINCODE === 2}
                  >

                    <span className=" flex justify-center items-center">
                      {item.icon}
                    </span>
                    <div
                      className={`justify-start items-center px-2 flex transition-all duration-500 overflow-hidden`}
                    >
                      <div
                        className={`text-center font-semibold text-[14px]  ${active === item.id ? "font-medium" : "font-normal"
                          } ${!item?.active ? "text-[#C0C0C1]" : "var(--content-main-black-light)"}`}
                        id={!item?.active && "my-anchor-element-id"}
                      >
                        {item.text}
                      </div>
                    </div>

                    {counter === 1 && item.id === 1 && (
                      <div className="absolute mobile:min-w-[260px] mobile:left-8
                                        mobile-375:min-w-[321px] mobile-375:left-[2rem]
                        
                                    flex-wrap min-h-[98px] rounded-[16px] -top-[5rem] -translate-y-1/2   flex justify-start items-center bg-[var(--bacground-component-module-light)] py-4 pl-10 pr-4 z-50">
                        <div className="absolute -left-2 -bottom-[1rem] -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                          <span className="text-[14px] text-[#000] font-normal">
                            {counter}
                          </span>
                        </div>
                        <p className="text-[18px] text-left text-[var(--content-main-black-light)] leading-[21.78px] font-normal">
                          {HighlightWords(t("Vank.Home.Onboarding.One.text"), [
                            t("Vank.Home.Onboarding.One.boldText").toLowerCase(),
                          ])}
                        </p>
                      </div>
                    )}
                  </button>
                  {index === 0 && counter === 3 && (
                      <div className="absolute w-[305px] left-[calc(50%-150px)] min-h-[98px] rounded-[16px] -top-[8rem] flex justify-start items-center bg-[var(--bacground-component-module-light)] py-4 px-4 z-50">
                      <div className="absolute left-0 right-0 mx-auto -bottom-[1.5rem] -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                        <span className="text-[14px] text-[#000] font-normal">
                          {counter}
                        </span>
                      </div>
                      <p className="text-[18px] text-center text-[var(--content-main-black-light)] leading-[21.78px] font-normal">
                        {t("Vank.Home.Onboarding.Three")}
                      </p>
                    </div>
                  )}
                </>
              ))}

            </div>
          </div>
          <Onboarding />
        </div>
      </div>
    </div>
  );
};
