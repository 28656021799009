import React from 'react'

const Error = ({
    className,
    fill = "#97CB0A",
    onClick,
}: {
    className?: any;
    fill?: any;
    onClick?: () => void;
}) => {
    return (
        <svg
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            fill={fill ? fill : "#A7A7AF"}
            stroke={fill ? fill : "#A7A7AF"}
            stroke-width="0.00048000000000000007"
            className={className}
            onClick={onClick}
        >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
                {" "}
                <title>exclamation-point</title>{" "}
                <g id="Layer_2" data-name="Layer 2">
                    {" "}
                    <g id="invisible_box" data-name="invisible box">
                        {" "}
                        <rect width="48" height="48" fill="none"></rect>{" "}
                    </g>{" "}
                    <g id="icons_Q2" data-name="icons Q2">
                        {" "}
                        <g>
                            {" "}
                            <circle cx="24" cy="40" r="3"></circle>{" "}
                            <path d="M23.8,33h.4a2.2,2.2,0,0,0,2.1-2L28,7.3a4,4,0,1,0-8,0L21.7,31A2.2,2.2,0,0,0,23.8,33Z"></path>{" "}
                        </g>{" "}
                    </g>{" "}
                </g>{" "}
            </g>
        </svg>
    )
}

export default Error