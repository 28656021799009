import React, { useEffect, useRef, useState } from 'react'
import imgProfile from '@assets/imgProfile.png'
import Banner1 from "@assets/img/imgsBanner2/banner1.png";
import Banner2 from "@assets/img/imgsBanner2/banner2.png";
import Banner3 from "@assets/img/imgsBanner2/banner3.png";
import Banner4 from "@assets/img/imgsBanner2/banner4.png";
import Banner5 from "@assets/img/imgsBanner2/banner5.png";
import { useTranslation } from 'react-i18next';
import { IndexedDBLocalDropdownRepository } from '@/apps/Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository';

const SidebarProfile = () => {
    const [t, i18n] = useTranslation("global");

    const slides = [
        { url: Banner1 },
        { url: Banner2 },
        { url: Banner3 },
        { url: Banner4 },
        { url: Banner5 },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isOpenSend, setIsOpenSend] = useState(false);
    const [user, setUser] = useState<{ avatar: string, name: string, email: string, vankId: string } | null>(null);


    const prevSlide = () => {
        setCurrentIndex((curr) => (curr === 0 ? slides?.length - 1 : curr - 1));
    };

    const nextSlide = () => {
        setCurrentIndex((curr) => (curr === slides?.length - 1 ? 0 : curr + 1));
    };

    useEffect(() => {
        const slideInterval = setInterval(nextSlide, 4000);

        return () => clearInterval(slideInterval);
    }, [currentIndex]);

    useEffect(() => {
        new IndexedDBLocalDropdownRepository()
            .get("user")
            .then((userStored) => {
                const user = userStored[0];
                if (user) {
                    setUser({
                        avatar: user?.AVATAR,
                        name: user?.COMPLETENAME?.split(" ")[0]+" "+user?.SURNAME?.split(" ")[0],
                        email: user?.EMAILUSERS,
                        vankId: user?.VANKID,
                    });
                }
                console.log(user," Useeeer");
            })
            .catch((error) => {
                console.error(error);
            });

           
            

        document.addEventListener('SliderProfile', () => setIsOpenSend(true))
    }, []);

    const goToSlide = (slideIndex: number) => {
        setCurrentIndex(slideIndex);
    };


    return (
        <div className={`absolute top-0 right-0 w-[371px] min-h-screen flex flex-col justify-between items-center py-[32px] bg-[--color-lightest-white] shadow z-40 ${isOpenSend ? 'translate-x-0' : 'translate-x-full opacity-10'} transition-all duration-700`}
        // ref={divRef}
        >
            <div className='w-full h-[335px] xl:h-[500px] 2xl:h-[635px]'>
                <div className='w-full h-[44px] flex justify-between py-2 px-4'>
                    <span className='text-base text-[--color-darkest-grey]'>{t("Vank.Share.ProfileModal.Profile")}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" onClick={() => setIsOpenSend(false)}>
                        <path d="M5 5L13 13" stroke="#818282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13 5L5 13" stroke="#818282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div className='w-full h-[285px] xl:h-[385px] 2xl:h-[485px]'>
                    <div className='w-full h-[181px] 2xl:h-[281px] bg-[--color-lighter-white] flex flex-col justify-center items-center'>
                        <div className='w-full h-[200px] flex flex-col items-center gap-[20px] 2xl:gap-[24px]'>
                            <img className="w-[95px] 2xl:w-[122px] h-[95px] 2xl:h-[123px] rounded-full" src={user?.avatar} />
                            <div className='w-full h-[53px] flex flex-col items-center'>
                                <span className="text-[--color-darkest-grey] text-base font-normal leading-none">{t("Vank.Share.ProfileModal.Name", { name: user?.name })}</span>
                                <span className="text-[#818282] text-sm font-normal">{t("Vank.Share.ProfileModal.Email", { Email: user?.email })}</span>
                                <div className="min-w-[147px] h-[17px] justify-center items-center gap-1 inline-flex">
                                    <div className="text-[#818282] text-sm font-normal">Vank ID</div>
                                    <div className="text-[--color-darkest-grey] text-sm font-normal">{user?.vankId}</div>
                                    <div className="w-3 h-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M2.99995 5.5C2.99995 4.08578 2.99995 3.37868 3.43929 2.93934C3.87863 2.5 4.58574 2.5 5.99995 2.5H7.49995C8.91415 2.5 9.62125 2.5 10.0606 2.93934C10.5 3.37868 10.5 4.08578 10.5 5.5V8C10.5 9.4142 10.5 10.1213 10.0606 10.5606C9.62125 11 8.91415 11 7.49995 11H5.99995C4.58574 11 3.87863 11 3.43929 10.5606C2.99995 10.1213 2.99995 9.4142 2.99995 8V5.5Z" stroke="#818282" stroke-width="1.03333" />
                                            <path opacity="0.5" d="M2.99997 9.5C2.17154 9.5 1.49997 8.82845 1.49997 8V5C1.49997 3.11438 1.49997 2.17157 2.08575 1.58578C2.67154 1 3.61435 1 5.49997 1H7.49997C8.32842 1 8.99997 1.67157 8.99997 2.5" stroke="#818282" stroke-width="1.03333" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full min-h-[131px] rounded-xl flex-col justify-center items-center gap-2 inline-flex">

                        <div className="h-[51px] flex justify-center items-center cursor-pointer">
                            <span className='text-[--color-darkest-grey] text-base font-normal hover:font-semibold '>{t("Vank.Share.ProfileModal.Link.EditProfile")}</span>
                        </div>

                        <span className="w-full h-px bg-[#F1F1F1]"></span>

                        <div className="h-[41px] 2xl:h-[51px] flex justify-center items-center cursor-pointer">
                            <span className='text-[--color-darkest-grey] text-base font-normal hover:font-semibold '>{t("Vank.Share.ProfileModal.Link.SettingsAndPrivacy")}</span>
                        </div>

                        <span className="w-full h-px bg-[#F1F1F1]" />

                        <div className="h-[41px] 2xl:h-[51px] flex justify-center items-center cursor-pointer">
                            <span className='text-[--color-darkest-grey] text-base font-normal hover:font-semibold '>{t("Vank.Share.ProfileModal.Link.Support")}</span>
                        </div>

                        <span className="w-full h-px bg-[#F1F1F1]" />

                        <div className="h-[41px] 2xl:h-[51px] flex justify-center items-center cursor-pointer">
                            <span className='text-[--color-darkest-grey] text-base font-normal hover:font-semibold '>{t("Vank.Share.ProfileModal.Link.ShareApp")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative w-[307px] h-[201px] rounded-[16px] overflow-hidden '>
                <img src={slides[currentIndex].url} alt="" className='w-full h-full object-cover rounded-2xl' />
                {/* indicator */}
                <div className="absolute left-1/2 -translate-x-1/2 w-[80px] h-1 justify-center items-end gap-[8px] inline-flex bottom-[8px]">
                    {slides.map((slide, slideIndex) => (
                        <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className={`h-[8px] ${currentIndex === slideIndex
                                ? "bg-[--color-darkest-grey] w-[16px]"
                                : "bg-[#C0C0C1] w-[8px]"
                                } rounded-[4px] transition-all duration-500`}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SidebarProfile