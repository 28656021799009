import { set } from 'date-fns';
import React, { useState } from 'react'

const ButtonExpand: React.FC<{ setExpandebutton?: () => void; className?: string; svgElementOpen?: React.ReactNode; svgElementClose?: React.ReactNode  }> = ({ setExpandebutton, className, svgElementOpen, svgElementClose }) => {
    //icono para expandir
    const svgElement1 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
        <path d="M1 1H11" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 5H11" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 9H11" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )

    //icono para cerrar <-
    const svgElement2 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
        <path d="M1.5 5.01953L5.5 8.85706" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.5 5.01953H11.5" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.5 4.98047L5.5 1.14294" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )

    return (
        <div>
            <button
                className={className}
                onClick={setExpandebutton}
            >
                {}
                {svgElementOpen == true ? svgElement1() : svgElementClose == true ? svgElement2() : ''}
            </button>
        </div>
    )

    
}

export default ButtonExpand
