import {
  useToastDispatch,
  useToastState,
} from "@/Context/ToastContext/ToastContext";
import { Toast as ToastType } from "@/Context/ToastContext/types";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import "./Toast.css";

const Toast = ({ toast }: { toast: ToastType }) => {
  const [toasts, setToasts] = useToastState();

  const { type, message, autoClose, theme, hideProgressBar, position, id } =
    toast;

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef(null); // Ref para almacenar el timeout
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [progress, setProgress] = useState(0); // Ref para almacenar el timeout

  const { addToast, deleteToast, updateToast } = useToastDispatch();

  // Definir variantes de animación para cada posición
  const toastVariants = {
    "top-left": {
      hidden: { x: "-100%" },
      visible: {
        x: isHovered ? "-10%" : isLoading ? "-82%" : "-10%",
      },
      exit: {
        x: "-100%",
      },
    },
    "top-center": {
      hidden: { y: "-100%" },
      visible: {
        y: isHovered ? 0 : isLoading && status === 1 ? "82%" : 0,
      },
      exit: {
        y: "-100%",
      },
    },
    "top-right": {
      hidden: { x: "200%" },
      visible: {
        x: isHovered ? "10%" : isLoading ? "82%" : "10%",
      },
      exit: {
        x: "200%",
      },
    },
    "bottom-left": {
      hidden: { x: "-100%" },
      visible: {
        x: isHovered ? "-10%" : isLoading ? "-82%" : "-10%",
      },
      exit: {
        x: "-100%",
      },
    },
    "bottom-center": {
      hidden: { y: "100%" },
      visible: {
        y: isHovered ? 0 : isLoading && status === 1 ? "82%" : 0,
      },
      exit: {
        y: "100%",
      },
    },
    "bottom-right": {
      hidden: { x: "100%" },
      visible: {
        x: isHovered ? "10%" : isLoading ? "82%" : "10%",
      },
      exit: {
        x: "100%",
      },
    },
  };

  const startHideTimeout = () => {
    if (toast.reretry && toast.reretry === 1) {
      return;
    }
    setTimeout(() => {
      deleteToast({ id: id });
    }, autoClose);
  };

  const clearHideTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Limpiar el timeout cuando se hace hover
    }
  };

  useEffect(() => {
    // if (toast.status && toast.status === 0 && type === "success") {
    //   startHideTimeout();
    //   return;
    // }
    setStatus(toast?.status);

    if (
      autoClose > 0 &&
      // type === "loading" &&
      toast?.progress === 100 &&
      (toast.status === 1 || toast.status === 0)
    ) {
      setIsLoading(true);
    }
    // if (autoClose > 0 && type === "error" && toast?.progress === 100) {
    //   setIsLoading(true);
    // }

    if (
      toast.status === 2 &&
      autoClose > 0 &&
      type !== "loading" &&
      toast?.progress === 100
    ) {
      deleteToast({ id: id });
    }

    return () => {
      clearHideTimeout();
    };
  }, [toast]);

  const onClose = (id: string) => {
    deleteToast({ id: id });
  };

  const handleActionClick = () => {
    if (toast.action && toast.action.onClick) {
      toast.action.onClick();
    }
  };

  return (
    <motion.div
      ref={wrapperRef}
      variants={toastVariants[position]}
      initial="hidden"
      animate="visible"
      exit="exit"
      onClick={() => {
        // if (toast.progress === 100) {
        setIsHovered(!isHovered);
        // }
      }}
      // onMouseEnter={() => {
      //   setIsHovered(true);
      //   // clearHideTimeout();
      // }}
      // onMouseLeave={() => {
      //   setIsHovered(false);
      //   // startHideTimeout();
      // }}
      className="min-w-[422px] max-w-max min-h-[92px] flex flex-col bg-[#FFFFFF] shadow rounded-lg relative group cursor-pointer z-50"
      role="alert"
      transition={{ duration: 0.5 }}
    >
      <div
        className={`w-full min-h-[92px] flex ${
          position === "bottom-left" || position === "top-left"
            ? "flex-row-reverse"
            : "flex"
        } items-center gap-4 px-4 py-2`}
      >
        {toast.icon && typeof toast.icon === "string" ? (
          <div className="w-[64px] h-[56px] flex justify-center items-center">
            <div dangerouslySetInnerHTML={{ __html: toast.icon }} />
          </div>
        ) : (
          <div className="w-[64px] h-[56px] flex justify-center items-center">
            {toast.icon}
          </div>
        )}
        <div
          className={`min-w-[240px] max-w-[318px]  leading-[16.94px] ${
            isLoading && !isHovered ? "opacity-0" : "opacity-100"
          } flex items-center gap-2 pr-12 transition-all duration-500`}
        >
          <p className="text-[#2D2E2F]">{message}</p>
          {toast?.action && toast?.action?.onClick && (
            <button
              className="min-w-[75px] h-[24px] border border-[#818282] p-2 flex justify-center items-center rounded-[24px]"
              // onClick={handleActionClick}
              onClick={() => {
                handleActionClick();
                setIsLoading(true);
                setIsHovered(false);
              }}
            >
              <div className="text-[#818282] text-[12px]">
                {toast?.action?.text}
              </div>
            </button>
          )}
        </div>
        {((isLoading && toast.status === 0) || type !== "loading") && (
          <div
            className={`w-[28px] h-[28px] absolute ${
              position === "bottom-left" || position === "top-left"
                ? "-right-1 -top-1"
                : "-left-1 -top-1"
            } justify-center items-center hidden group-hover:flex group-hover:bg-[#FFFFFF] group-hover:shadow group-hover:rounded-full cursor-pointer`}
            onClick={() => onClose(id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5 5L13 13"
                stroke="#818282"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13 5L5 13"
                stroke="#818282"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      <div
        className={`min-h-[4px] bg-[#C0C0C1] ${
          !hideProgressBar ? "bg-transparent" : "bg-[#C0C0C1]"
        }
        ${
          (toast.reretry === 1 && !isHovered) ||
          (isLoading && toast.progress === 100)
            ? "opacity-0"
            : "opacity-100"
        } transition-all`}
        style={{ width: `${toast?.progress}%` }}
      ></div>
    </motion.div>
  );
};
export default Toast;
